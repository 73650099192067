<template>
    <div class="row" id="basic-table">
        <div class="col-12">
            <div class="card" :class="fixedfullscreen">
                <div class="card-header d-none d-md-block">
                    <h4 class="card-title">Mostrando los últimos {{showRecordsDay}} días <span v-if="showUserName">de <b>{{userName}}</b></span></h4>
                    <p v-if="records.length > 0"><b>Total: {{records.length}} resultados </b></p>
                </div>
                <div class="mb-2">
                    <div class="card-text">      
                        <div class="row justify-content-end align-items-center">
                            <div class="col-md-3 mt-1"> 
                                <div class="form-check form-check-inline d-block m-1">
                                    <input class="form-check-input" type="checkbox" id="column1" value="checked" :checked="tipeSearch" @click="changeSearchType()">
                                    <label class="form-check-label" for="column1">Buscar por fecha</label>
                                </div>   
                            </div>

                            <div class="col-md-4 mt-1"> 
                                <select v-if="!tipeSearch" class="form-select" v-model="showRecordsDay" @change="findRecord()">
                                    <option value="7">Últimos 7 días</option>
                                    <option value="15">Últimos 15 días</option>
                                    <option value="30">Últimos 30 días</option>
                                    <option value="60">Últimos 60 días</option>
                                    <option value="90">Últimos 90 días</option>
                                    <option value="365">Últimos 365 días</option>
                                    <option value="3650">Mostrar todos</option>
                                </select> 
                                <div v-if="tipeSearch" class="input-group form-password-toggle">   
                                    <input v-if="tipeSearch" type="date" class="form-control" placeholder="" v-model="dateSearch">
                                    <span class="input-group-text cursor-pointer"><i class="las la-search" @click="searchDate()"></i></span>
                                </div>   
                            </div>
                            <div class="col-9 col-md-4 mt-1">
                                <div class="d-flex w-100 justify-content-start align-items-center" v-if="hideColumsBtn">
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow py-0 waves-effect waves-float waves-light" data-bs-toggle="dropdown">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                            Mostrar Columnas
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end" style="width:350px">            
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column1" value="checked" :checked="column1" @click="showHideColum(1)">
                                                <label class="form-check-label" for="column1">Teléfono</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column2" value="checked" :checked="column2" @click="showHideColum(2)">
                                                <label class="form-check-label" for="column2">Email</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column3" value="checked" :checked="column3" @click="showHideColum(3)">
                                                <label class="form-check-label" for="column3">Genero</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column4" value="checked" :checked="column4" @click="showHideColum(4)">
                                                <label class="form-check-label" for="column4">País</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column18" value="checked" :checked="column18" @click="showHideColum(18)">
                                                <label class="form-check-label" for="column18">Fecha de Registro</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column5" value="checked" :checked="column5" @click="showHideColum(5)">
                                                <label class="form-check-label" for="column5">Total Publicaciones</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column6" value="checked" :checked="column6" @click="showHideColum(6)">
                                                <label class="form-check-label" for="column6">Total Publicaciones Online</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column7" value="checked" :checked="column7" @click="showHideColum(7)">
                                                <label class="form-check-label" for="column7">Total Publicaciones Presenciales</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column8" value="checked" :checked="column8" @click="showHideColum(8)">
                                                <label class="form-check-label" for="column8">Total Publicaciones Pausadas por el usuario</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column9" value="checked" :checked="column9" @click="showHideColum(9)">
                                                <label class="form-check-label" for="column9">Total Publicaciones Pausadas por Animasana</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column10" value="checked" :checked="column10" @click="showHideColum(10)">
                                                <label class="form-check-label" for="column10">Total de ventas</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column11" value="checked" :checked="column11" @click="showHideColum(11)">
                                                <label class="form-check-label" for="column11">Total de ventas desde Animasana</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column12" value="checked" :checked="column12" @click="showHideColum(12)">
                                                <label class="form-check-label" for="column12">Total de ventas desde Landing</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column13" value="checked" :checked="column13" @click="showHideColum(13)">
                                                <label class="form-check-label" for="column13">Total de ventas manuales</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column14" value="checked" :checked="column14" @click="showHideColum(14)">
                                                <label class="form-check-label" for="column14">Total de Contactos</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column15" value="checked" :checked="column15" @click="showHideColum(15)">
                                                <label class="form-check-label" for="column15">Pasarela Local</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column16" value="checked" :checked="column16" @click="showHideColum(16)">
                                                <label class="form-check-label" for="column16">Pasarela Internacional</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column17" value="checked" :checked="column17" @click="showHideColum(17)">
                                                <label class="form-check-label" for="column17">Plan</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column20" value="checked" :checked="column19" @click="showHideColum(20)">
                                                <label class="form-check-label" for="column20">Referido</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column21" value="checked" :checked="column19" @click="showHideColum(21)">
                                                <label class="form-check-label" for="column21">Cupon</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column22" value="checked" :checked="column19" @click="showHideColum(22)">
                                                <label class="form-check-label" for="column22">Dto.</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column23" value="checked" :checked="column19" @click="showHideColum(23)">
                                                <label class="form-check-label" for="column23">Meses</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column24" value="checked" :checked="column19" @click="showHideColum(24)">
                                                <label class="form-check-label" for="column24">Suscripto</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column19" value="checked" :checked="column19" @click="showHideColum(19)">
                                                <label class="form-check-label" for="column19">Web</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 col-md-1 mt-1">
                                <div class="d-flex justify-content-end align-items-center">        
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-outline-primary dropdown-toggle hide-arrow waves-effect waves-float waves-light" @click="exportOption()">
                                            <i class="las la-file-csv"></i> Exportar
                                        </button>
                                    </div>
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow py-0 waves-effect waves-float waves-light" @click="maximize()">
                                            <i class="las fs-30" :class="spandIcon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="table-responsive h-100">
                    <table class="table table-striped position-relative">
                        <thead class="fixed-head-table">
                            <tr>
                                <th colspan="1" class="text-center bg-my"></th>
                                <th v-if="title1 > 0" :colspan="title1" class="text-center bg-light-success">INFORMACIÓN</th>
                                <th v-if="title2 > 0" :colspan="title2" class="text-center bg-light-warning">PUBLICACIONES</th>
                                <th v-if="column8" colspan="1" class="text-center bg-light-warning"><h4 class="m-0 text-warning" title="Pausadas por el usuario"><i class="lar la-user"></i></h4></th>
                                <th v-if="column9" colspan="1" class="text-center bg-light-warning"><h4 class="m-0 text-warning" title="Pausadas por Animasana"><i class="las la-user-cog"></i></h4></th>
                                <th v-if="title3 > 0" :colspan="title3" class="text-center bg-light-primary">VENTAS</th>
                                <th v-if="title4 > 0" :colspan="title4" class="text-center bg-light-info">PASARELAS</th>
                                <th v-if="title5 > 0" :colspan="title5" class="text-center bg-light-success">SUSCRIPCIÓN</th>
                                
                                <th colspan="1" class="text-center bg-light-info"></th>
                            </tr>
                            <tr>
                                <th class="text-center bg-my text-success sticky"><div class="d-flex align-items-center justify-content-center">Nombre <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('userFirstName')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('userFirstName')"></i></span></div></th>
                                <th v-if="column1" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Teléfono</div></th>
                                <th v-if="column2" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Email <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('userMail')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('userMail')"></i></span></div></th>
                                <th v-if="column3" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Genero <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('userGender')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('userGender')"></i></span></div></th>
                                <th v-if="column4" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">País <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('userCountry')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('userCountry')"></i></span></div></th>
                                <th v-if="column18" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Registro <span><i class="las la-caret-up d-block arrow-short" @click="shortDateASC('regDate')"></i><i class="las la-caret-down d-block arrow-short" @click="shortDateDESC('regDate')"></i></span></div></th>
                                <th v-if="column5" class="text-center text-warning" title= "Total Publicaciones"><div class="d-flex align-items-center justify-content-center">Total <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalPublsih')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalPublsih')"></i></span></div></th>
                                <th v-if="column6" class="text-center text-warning" title= "Total Publicaciones Online"><div class="d-flex align-items-center justify-content-center">Online <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalOnline')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalOnline')"></i></span></div></th>
                                <th v-if="column7" class="text-center text-warning" title= "Total Publicaciones Presenciales"><div class="d-flex align-items-center justify-content-center">Presencial <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalPresencial')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalPresencial')"></i></span></div></th>
                                <th v-if="column8" class="text-center text-warning" title= "Total Publicaciones Pausadas por el usuario"><div class="d-flex align-items-center justify-content-center">Pausadas <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalPauseUser')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalPauseUser')"></i></span></div></th>
                                <th v-if="column9" class="text-center text-warning" title= "Total Publicaciones Pausadas por Animasana"><div class="d-flex align-items-center justify-content-center">Pausadas <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalPauseAdmin')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalPauseAdmin')"></i></span></div></th>
                                <th v-if="column10" class="text-center text-primary"><div class="d-flex align-items-center justify-content-center">Totales <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalSales')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalSales')"></i></span></div></th>
                                <th v-if="column11" class="text-center text-primary"><div class="d-flex align-items-center justify-content-center">Animasana <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalSalesAnim')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalSalesAnim')"></i></span></div></th>
                                <th v-if="column12" class="text-center text-primary"><div class="d-flex align-items-center justify-content-center">Landing <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalSalesLanding')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalSalesLanding')"></i></span></div></th>
                                <th v-if="column13" class="text-center text-primary"><div class="d-flex align-items-center justify-content-center">Manuales <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalSalesManual')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalSalesManual')"></i></span></div></th>
                                <th v-if="column14" class="text-center text-primary"><div class="d-flex align-items-center justify-content-center">Contacto <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalContacts')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalContacts')"></i></span></div></th>
                                <th v-if="column15" class="text-center text-info"><div class="d-flex align-items-center justify-content-center">Local <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('pasarelaLocal')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('pasarelaLocal')"></i></span></div></th>
                                <th v-if="column16" class="text-center text-info"><div class="d-flex align-items-center justify-content-center">Internacional <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('pasarelaGlobal')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('pasarelaGlobal')"></i></span></div></th>
                                <th v-if="column17" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Plan <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('userPlan')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('userPlan')"></i></span></div></th>   
                                <th v-if="column20" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Referido<span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('recruiterId')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('recruiterId')"></i></span></div></th>
                                <th v-if="column21" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Cupon<span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('coupon')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('coupon')"></i></span></div></th>
                                <th v-if="column22" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Descuento</div></th>
                                <th v-if="column23" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Meses</div></th>
                                <th v-if="column24" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Tarjeta <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('card_added')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('card_added')"></i></span></div></th>
                                <th v-if="column19" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Web</div></th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="b in records" :key="b.id"> 
                                <td class="sticky">
                                    <div class="d-flex align-items-center" style="width: 250px;"> 
                                        <div class="w-25 text-center">
                                            <span class="badge rounded-pill badge-light-warning me-1" v-if="b.userBlocked == 1">Bloqueado</span>
                                            <img v-if="b.userImg != '@/assets/imgs/user-img-small.png'" :src="b.userImg" class="" height="20" width="20" >
                                            <img v-else src="@/assets/imgs/user-img-small-gradient.png" class="" height="20" width="20">
                                        </div>
                                        <span class="fw-bold m-1"><a :href="linkStart+ b.userId"><i class="las la-eye pointer" title="Ver Ficha"></i></a> {{b.userFirstName}} {{b.userLastName}}</span> 
                                    </div>   
                                </td>
                                <td v-if="column1 && b.userPhone != ''"><a :href="'https://wa.me/'+b.userPhone" target="blank" class=""><div style="width: 120px;"><i class="lab la-whatsapp"></i> {{b.userPhone}}</div></a></td>
                                <td v-if="column1 && b.userPhone == ''"></td>
                                <td v-if="column2 && b.userMail != ''"><a :href="'mailto:'+b.userMail" target="blank" class="">{{b.userMail}}</a></td>
                                <td v-if="column2 && b.userMail == ''"></td>
                                <td class="text-center" v-if="column3 && b.userGender == 0">--</td>
                                <td class="text-center" v-if="column3 && b.userGender == 1"><i class="las la-mars"></i></td>
                                <td class="text-center" v-if="column3 && b.userGender == 2"><i class="las la-venus"></i></td>
                                <td class="text-center" v-if="column3 && b.userGender == 3"><i class="las la-venus"></i></td>
                                <td v-if="column4" class="text-center">{{b.userCountry}}</td>  
                                <td v-if="column18" class="text-center">{{b.regDate}}</td>  
                                <td v-if="column5" class="text-center"><span class="badge rounded-pill badge-light-info me-1 pointer" @click="expandInfo('publications', b.userCode, b.userFirstName+' '+b.userLastName, '', '')"><i class="las la-info"></i> {{b.totalPublsih}}</span></td>
                                <td v-if="column6" class="text-center"><span class="badge rounded-pill badge-light-info me-1">{{b.totalOnline}}</span></td>
                                <td v-if="column7" class="text-center"><span class="badge rounded-pill badge-light-info me-1">{{b.totalPresencial}}</span></td>
                                <td v-if="column8" class="text-center"><span class="badge rounded-pill badge-light-danger me-1">{{b.totalPauseUser}}</span></td>
                                <td v-if="column9" class="text-center"><span class="badge rounded-pill badge-light-danger me-1">{{b.totalPauseAdmin}}</span></td>
                                <td v-if="column10" class="text-center"><span class="badge rounded-pill badge-light-info me-1 pointer" @click="expandInfo('sales', b.userCode, b.userFirstName+' '+b.userLastName, '1', '')"><i class="las la-info"></i> {{b.totalSales}}</span></td>
                                <td v-if="column11" class="text-center"><span class="badge rounded-pill badge-light-info me-1">{{b.totalSalesAnim}}</span></td>
                                <td v-if="column12" class="text-center"><span class="badge rounded-pill badge-light-info me-1">{{b.totalSalesLanding}}</span></td>
                                <td v-if="column13" class="text-center"><span class="badge rounded-pill badge-light-info me-1">{{b.totalSalesManual}}</span></td>
                                <td v-if="column14" class="text-center"><span class="badge rounded-pill badge-light-info me-1 pointer" @click="expandInfo('consultants', b.userCode, b.userFirstName+' '+b.userLastName, '', '')"><i class="las la-info"></i> {{b.totalContacts}}</span></td>
                                <td class="text-center" v-if="column15 && b.pasarelaLocal < 3">Animasana <br><b>{{b.currencyLocal}}</b></td>
                                <td class="text-center" v-if="column15 && b.pasarelaLocal == 3">Mercado Pago <br><b>{{b.currencyLocal}}</b></td>
                                <td class="text-center" v-if="column15 && b.pasarelaLocal == 4">PayPal <br><b>{{b.currencyLocal}}</b></td>
                                <td class="text-center" v-if="column16 && b.pasarelaGlobal < 3">Animasana <br><b>{{b.currencyGlobal}}</b></td>
                                <td class="text-center" v-if="column16 && b.pasarelaGlobal == 3">Mercado Pago <br><b>{{b.currencyGlobal}}</b></td>
                                <td class="text-center" v-if="column16 && b.pasarelaGlobal == 4">PayPal <br><b>{{b.currencyGlobal}}</b></td>
                                <td v-if="column17" class="text-center"><span class="badge rounded-pill badge-light-info me-1">{{b.userPlan}}</span></td>
                                <td v-if="column20" class="text-center">{{b.recruitFirstName}} {{b.recruitLastName}}</td>
                                <td v-if="column21" class="text-center">{{b.coupon}}</td>
                                <td v-if="column22 && b.discount == ''" class="text-center"></td>
                                <td v-if="column22 && b.discount != ''" class="text-center">{{b.discount}}%</td>
                                <td v-if="column23" class="text-center">{{b.discountTime}}</td>
                                <td v-if="column24" class="text-center">
                                    <button :id="'Yes'+b.userId" v-show="b.card_added == '1'" class="btn btn-outline-primary btn-sm me-1 waves-effect waves-float waves-light collapsed" @click="cancelCard(b.userId)" title="Cancelar Suscripción"><i class="las la-ban"></i> SI</button>    
                                    <div :id="'No'+b.userId" v-show="b.card_added == '0'">NO</div>
                                </td>
                                <td v-if="column19 && b.userWeb != ''" class="text-center"><a :href="'https://'+b.userWeb+'.animasana.net'" target="blank" class="">https://{{b.userWeb}}.animasana.net</a></td>
                                <td v-if="column19 && b.userWeb == ''" class="text-center"></td>
                                <td>
                                    <button v-if="adminType != '3'" class="btn btn-outline-primary btn-sm me-1 waves-effect waves-float waves-light collapsed" @click="assist(b.linkAssist)" title="Asistir"><i class="las la-headset"></i></button>    
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="tableSearch">
                    <div class="input-group form-password-toggle">   
                        <input type="text" class="form-control" placeholder="Buscar dentro de los resultados por profesional, consultante, terapia, titulo, ids de pagos" v-model="textSearch" @keyup="findinArray()" >
                        <span class="input-group-text cursor-pointer"><i class="las la-search" @change="findinArray()"></i></span>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>
import axios from "axios";
import StarRating from 'vue-star-rating'
export default {  
    props: ['refUser', 'showUserName', 'userName', 'mode', 'recruitRef', 'hideColumsBtn' ],
    name: "referals-form",
    components: {
        StarRating,
    },
    data(){
        return{
            records: [],
            recordsBkp: [],
            column1: true,
            column2: true,
            column3: true,
            column4: true,
            column5: true,
            column6: true,
            column7: true,
            column8: true,
            column9: true,
            column10: true,
            column11: true,
            column12: true,
            column13: true,
            column14: true,
            column15: true,
            column16: true,
            column17: true,
            column18: true,
            column19: true,
            column20: true,
            column21: true, //referido
            column22: true, //cupon
            column23: true, //descunto
            column24: true, //referido
            title1: 5,
            title2: 3,
            title3: 5,
            title4: 2,
            title5: 7,
            fixedfullscreen: '',
            spandIcon: 'la-expand-arrows-alt',
            showRecordsDay: 365,
            tipeSearch: false,
            dateSearch: '',
            exportArray: [],
            export: false,
            textSearch: '',
            refId: '',
            showPayOut: false,
            textSearch: '',
            linkStart: '',
            adminType: 0
        }
    },
    mounted: function () {  
        var data = JSON.parse(localStorage.getItem('userData'));
        this.adminType = data.userType;
        this.linkStart = '/' + this.$route.params.browserCountry + '/' + this.$route.params.userLang + '/user-account/';
        if(this.refUser != ''){
            this.refId = this.refUser;
            this.showRecordsDay = 365;
        }
        this.findRecord();
    },
    methods:{
        searchDate(){
            var timestamp = new Date(this.dateSearch);
            timestamp = timestamp.getTime();
            if(isNaN(timestamp) == false){
                this.findRecord();
            }
            
        },
        changeSearchType(){
            if(this.tipeSearch == false){
                this.tipeSearch = true;
                this.dateSearch = '';
            }else{
                this.tipeSearch = false;
                this.dateSearch = '';
                this.findRecord();
            }
        },
        shortDateASC(val){
            if(val == 'date'){
                this.records.sort((a, b) => Number(new Date(b.dateIniNoFormat)) - Number(new Date(a.dateIniNoFormat)));
            }    
            if(val == 'datePay'){
                this.records.sort((a, b) => Number(new Date(b.datePayNoFormat)) - Number(new Date(a.datePayNoFormat)));
            }  
            if(val == 'payout_date'){
                this.records.sort((a, b) => Number(new Date(b.datePaidNoFormat)) - Number(new Date(a.datePaidNoFormat)));
            }    
        },
        shortDateASC(val){
            if(val == 'regDate'){
                this.records.sort((a, b) => Number(new Date(b.regDateNoFormat)) - Number(new Date(a.regDateNoFormat)));
            }    
        },
        shortDateDESC(val){
            if(val == 'regDate'){
                this.records.sort((a, b) => Number(new Date(a.regDateNoFormat)) - Number(new Date(b.regDateNoFormat)));
            }    
        },
        shortNumbersASC(val){
            // sort by value
            if(val == 'pasarelaGlobal'){
                this.records.sort((a, b) => a.pasarelaGlobal - b.pasarelaGlobal);
            }
            if(val == 'pasarelaLocal'){
                this.records.sort((a, b) => a.pasarelaLocal - b.pasarelaLocal);
            }
            if(val == 'totalContacts'){
                this.records.sort((a, b) => a.totalContacts - b.totalContacts);
            }
            if(val == 'totalOnline'){
                this.records.sort((a, b) => a.totalOnline - b.totalOnline);
            }
            if(val == 'totalPresencial'){
                this.records.sort((a, b) => a.totalPresencial - b.totalPresencial);
            }
            if(val == 'totalPublsih'){
                this.records.sort((a, b) => a.totalPublsih - b.totalPublsih);
            }
            if(val == 'totalPauseUser'){
                this.records.sort((a, b) => a.totalPauseUser - b.totalPauseUser);
            }
            if(val == 'totalPauseAdmin'){
                this.records.sort((a, b) => a.totalPauseAdmin - b.totalPauseAdmin);
            }
            if(val == 'totalSales'){
                this.records.sort((a, b) => a.totalSales - b.totalSales);
            }
            if(val == 'totalSalesAnim'){
                this.records.sort((a, b) => a.totalSalesAnim - b.totalSalesAnim);
            }
            if(val == 'totalSalesLanding'){
                this.records.sort((a, b) => a.totalSalesLanding - b.totalSalesLanding);
            }
            if(val == 'totalSalesManual'){
                this.records.sort((a, b) => a.totalSalesManual - b.totalSalesManual);
            }
            if(val == 'userGender'){
                this.records.sort((a, b) => a.userGender - b.userGender);
            }
        },
        shortNumbersDESC(val){
            // sort by value
            if(val == 'pasarelaGlobal'){
                this.records.sort((a, b) => b.pasarelaGlobal - a.pasarelaGlobal);
            }
            if(val == 'pasarelaLocal'){
                this.records.sort((a, b) => b.pasarelaLocal - a.pasarelaLocal);
            }
            if(val == 'totalContacts'){
                this.records.sort((a, b) => b.totalContacts - a.totalContacts);
            }
            if(val == 'totalOnline'){
                this.records.sort((a, b) => b.totalOnline - a.totalOnline);
            }
            if(val == 'totalPresencial'){
                this.records.sort((a, b) => b.totalPresencial - a.totalPresencial);
            }
            if(val == 'totalPublsih'){
                this.records.sort((a, b) => b.totalPublsih - a.totalPublsih);
            }
            if(val == 'totalPauseUser'){
                this.records.sort((a, b) => b.totalPauseUser - a.totalPauseUser);
            }
            if(val == 'totalPauseAdmin'){
                this.records.sort((a, b) => b.totalPauseAdmin - a.totalPauseAdmin);
            }
            if(val == 'totalSales'){
                this.records.sort((a, b) => b.totalSales - a.totalSales);
            }
            if(val == 'totalSalesAnim'){
                this.records.sort((a, b) => b.totalSalesAnim - a.totalSalesAnim);
            }
            if(val == 'totalSalesLanding'){
                this.records.sort((a, b) => b.totalSalesLanding - a.totalSalesLanding);
            }
            if(val == 'totalSalesManual'){
                this.records.sort((a, b) => b.totalSalesManual - a.totalSalesManual);
            }
            if(val == 'userGender'){
                this.records.sort((a, b) => b.userGender - a.userGender);
            }
        },
        shortWordsASC(val){
            // sort by name
            this.records.sort((a, b) => {
                let nameA;
                let nameB;
            if(val == 'userFirstName'){
                nameA = a.userFirstName.toUpperCase(); // ignore upper and lowercase
                nameB = b.userFirstName.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'userMail'){
                nameA = a.userMail.toUpperCase(); // ignore upper and lowercase
                nameB = b.userMail.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'userCountry'){
                nameA = a.userCountry.toUpperCase(); // ignore upper and lowercase
                nameB = b.userCountry.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'userPlan'){
                nameA = a.userPlan.toUpperCase(); // ignore upper and lowercase
                nameB = b.userPlan.toUpperCase(); // ignore upper and lowercase
            }  
            if(val == 'coupon'){
                nameA = a.coupon.toUpperCase(); // ignore upper and lowercase
                nameB = b.coupon.toUpperCase(); // ignore upper and lowercase
            } 
            if(val == 'recruiterId'){
                nameA = a.recruiterId; // ignore upper and lowercase
                nameB = b.recruiterId; // ignore upper and lowercase
            }  
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
            });

        },
        shortWordsDESC(val){
            // sort by name
            this.records.sort((a, b) => {
                let nameA;
                let nameB;
            if(val == 'userFirstName'){
                nameA = a.userFirstName.toUpperCase(); // ignore upper and lowercase
                nameB = b.userFirstName.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'userMail'){
                nameA = a.userMail.toUpperCase(); // ignore upper and lowercase
                nameB = b.userMail.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'userCountry'){
                nameA = a.userCountry.toUpperCase(); // ignore upper and lowercase
                nameB = b.userCountry.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'userPlan'){
                nameA = a.userPlan.toUpperCase(); // ignore upper and lowercase
                nameB = b.userPlan.toUpperCase(); // ignore upper and lowercase
            }  
            if(val == 'coupon'){
                nameA = a.coupon.toUpperCase(); // ignore upper and lowercase
                nameB = b.coupon.toUpperCase(); // ignore upper and lowercase
            } 
            if(val == 'recruiterId'){
                nameA = a.recruiterId; // ignore upper and lowercase
                nameB = b.recruiterId; // ignore upper and lowercase
            }  
            if (nameA > nameB) {
                return -1;
            }
            if (nameA < nameB) {
                return 1;
            }

            // names must be equal
            return 0;
            });
        },
        maximize(){
            if(this.fixedfullscreen == ""){
                this.fixedfullscreen = "fixed-full-screen";
                this.spandIcon = "la-compress-arrows-alt";
            }else{
                this.fixedfullscreen = "";
                this.spandIcon = "la-expand-arrows-alt";
            }
        },
        showHideColum(val){
            if(val == 1){
                if(this.column1 == true){
                    this.column1 = false;
                    this.title1--;
                }else{
                    this.column1 = true;
                    this.title1++;
                }
            }else if(val == 2){
                if(this.column2 == true){
                    this.column2 = false;
                    this.title1--;
                }else{
                    this.column2 = true;
                    this.title1++;
                }
            }else if(val == 3){
                if(this.column3 == true){
                    this.column3 = false;
                    this.title1--;
                }else{
                    this.column3 = true;
                    this.title1++;
                }
            }else if(val == 4){
                if(this.column4 == true){
                    this.column4 = false;
                    this.title1--;
                }else{
                    this.column4 = true;
                    this.title1++;
                }
            }else if(val == 5){
                if(this.column5 == true){
                    this.column5 = false;
                    this.title2--;
                }else{
                    this.column5 = true;
                    this.title2++;
                }
            }else if(val == 6){
                if(this.column6 == true){
                    this.column6 = false;
                    this.title2--;
                }else{
                    this.column6 = true;
                    this.title2++;
                }
            }else if(val == 7){
                if(this.column7 == true){
                    this.column7 = false;
                    this.title2--;
                }else{
                    this.column7 = true;
                    this.title2++;
                }
            }else if(val == 8){
                if(this.column8 == true){
                    this.column8 = false;
                }else{
                    this.column8 = true;
                }
            }else if(val == 9){
                if(this.column9 == true){
                    this.column9 = false;
                }else{
                    this.column9 = true;
                }
            }else if(val == 10){
                if(this.column10 == true){
                    this.column10 = false;
                    this.title3--;
                }else{
                    this.column10 = true;
                    this.title3++;
                }
            }else if(val == 11){
                if(this.column11 == true){
                    this.column11 = false;
                    this.title3--;
                }else{
                    this.column11 = true;
                    this.title3++;
                }
            }else if(val == 12){
                if(this.column12 == true){
                    this.column12 = false;
                    this.title3--;
                }else{
                    this.column12 = true;
                    this.title3++;
                }
            }else if(val == 13){
                if(this.column13 == true){
                    this.column13 = false;
                    this.title3--;
                }else{
                    this.column13 = true;
                    this.title3++;
                }
            }else if(val == 14){
                if(this.column14 == true){
                    this.column14 = false;
                    this.title3--;
                }else{
                    this.column14 = true;
                    this.title3++;
                }
            }else if(val == 15){
                if(this.column15 == true){
                    this.column15 = false;
                }else{
                    this.column15 = true;
                }
            }else if(val == 16){
                if(this.column16 == true){
                    this.column16 = false;
                    this.title5--;
                }else{
                    this.column16 = true;
                     this.title5++;
                }
            }else if(val == 18){
                if(this.column18 == true){
                    this.column18 = false;
                     this.title1--;
                }else{
                    this.column18 = true;
                    this.title1++;
                }

            }else if(val == 17){
                if(this.column17 == true){
                    this.column17 = false;
                     this.title5--;
                }else{
                    this.column17 = true;
                    this.title5++;
                } 
            }else if(val == 19){
                if(this.column19 == true){
                    this.column19 = false;
                     this.title5--;
                }else{
                    this.column19 = true;
                    this.title5++;
                } 
            }else if(val == 20){
                if(this.column20 == true){
                    this.column20 = false;
                     this.title5--;
                }else{
                    this.column20 = true;
                    this.title5++;
                } 
            }else if(val == 21){
                if(this.column21 == true){
                    this.column21 = false;
                     this.title5--;
                }else{
                    this.column21 = true;
                    this.title5++;
                } 
            }else if(val == 22){
                if(this.column22 == true){
                    this.column22 = false;
                     this.title5--;
                }else{
                    this.column22 = true;
                    this.title5++;
                } 
            }else if(val == 23){
                if(this.column23 == true){
                    this.column23 = false;
                     this.title5--;
                }else{
                    this.column23 = true;
                    this.title5++;
                } 
           
        
            }else if(val == 24){
                if(this.column24 == true){
                    this.column24 = false;
                    this.title5--;
                }else{
                    this.column24 = true;
                    this.title5++;
                }
            

            }
        },
        exportOption(){
            this.export = true;
            this.findRecord();
        },
        download(dataurl, filename) {
            const link = document.createElement("a");
            link.href = dataurl;
            link.download = filename;
            link.click();
        },
        async findRecord(){ 
            if(this.export == true){
                this.exportArray = this.records;
            }else{
                this.exportArray = '';
                this.records = [];
            }
            var payload = {
                action: 'admin-get-referals',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                show: this.showRecordsDay,
                dateSearch: this.dateSearch,
                exportArray: this.exportArray,
                ref: this.refId,
                mode: this.mode,
            }
            await axios.post('/index.php', payload).then(result => {
                if(this.export == true){
                    if(window.location.host == 'back.animasana.net'){
                        this.download('https://api.animasana.net' + result.data.file, 'nombre.xlsx');  
                    }else if(window.location.host == 'api-qa.animasana.net'){
                        this.download('https://api-qa.animasana.net' + result.data.file, 'nombre.xlsx');  
                    }else{
                       this.download('https://api-qa.animasana.net' + result.data.file, 'nombre.xlsx'); 
                    }
                }else{
                    this.records = result.data; 
                    this.recordsBkp = result.data; 
                }
                this.export = false;
            })
            .catch(error => {
                return;
            })  
        },
        findinArray(){
            var matches = this.recordsBkp;
            this.records = matches.filter(
                s => s.sellerName.toLowerCase().includes(this.textSearch.toLowerCase()) 
                || s.clientName.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.title.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.service.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.sellId.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.payId.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.cashout_id.toLowerCase().includes(this.textSearch.toLowerCase())
            );
        },

     }
}
</script>
<style scoped>
.tableSearch{
    position: sticky;
    z-index: 9;
    bottom: 15px;
    width: 40%;
    background: #6e6b7bbd;
    padding: 10px;
    margin: auto;
    border-radius: 5px;
}
.fixed-full-screen .tableSearch{
position: fixed;
    bottom: 48px;
    left: 0;
    right: 0;
    }
@media (max-width: 600px) {
    .tableSearch{
    width: 95%;
    }
}
@media (min-width: 600px) {
th.bg-my{
    position:sticky;
  left:0px;
  z-index: 2;
}
td.sticky
{
  position:sticky;
  left:0px;
  z-index: 2;
  background: #fff;
}
.dark-layout td.sticky
{
  position:sticky;
  left:0px;
  z-index: 2;
  background: #283046;
}
tr.sticky:not(caption) > *{
    box-shadow: inset 0 0 0 9999px #545454;
}

.dark-layout tr.sticky:not(caption) > *{
    box-shadow: inset 0 0 0 9999px #545454;
}
}
.fixed-full-screen{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 5px;
    width: 100%;
    height: 100%;
    background: #f7f7f7;
    overflow: auto;
    right: 0;
    padding: 10px;
}
.dark-layout .fixed-full-screen{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 5px;
    width: 100%;
    height: 100%;
    background: #161d31;
    overflow: auto;
    right: 0;
    padding: 10px;
}
.pointer{
    cursor: pointer;
}
.fixed-head-table{
    position: sticky;
    top: 0;
    background: #343d55;
    z-index: 4;
}
.dark-layout .fixed-head-table{
    position: sticky;
    top: 0;
    background: #343d55;
    z-index: 4;
}
.arrow-short{
    margin-left: 10px;
}
.fs-30{
    font-size: 30px;
}
</style>