<template>
    <div id="myHelp" :class="maximize">
        
        <!-- Include a header DIV with the same name as the draggable DIV, followed by "header" -->
        <div id="myHelpheader">
            <div>
                <i class="las la-braille"></i> 
                <i class="las la-times pointer" @click="closeModal()"></i>
                <!--<i class="las pointer" :class="maximizeIcon" @click="maxMin()"></i>-->
            </div>
            <div><i class="las la-info"></i> <span>{{title}}</span></div>
        </div>
        <div class="content-help" :class="full">
            <div class="container overflow-auto">
                <div class="content-help-info">  
                    <publications-form v-if="this.type == 'publications'" :refUser="this.refId" :showUserName="true" :userName="this.userName"></publications-form>
                    <publications-workshop-form v-if="this.type == 'publications-workshop'" :refUser="this.refId" :showUserName="true" :userName="this.userName"></publications-workshop-form>
                    <consultants-form v-if="this.type == 'consultants'" :refUser="this.refId" :showUserName="true" :userName="this.userName"></consultants-form>
                    <professionals-sales-form v-if="this.type == 'sales'" :refUser="this.refId" :showUserName="true" :userName="this.userName" :mode="this.mode" :publishRef="this.publishRef"></professionals-sales-form>
                    <professionals-sales-workshop-form v-if="this.type == 'sales-workshop'" :refUser="this.refId" :showUserName="true" :userName="this.userName" :mode="this.mode" :publishRef="this.publishRef"></professionals-sales-workshop-form>
                    <professionals-form v-if="this.type == 'professionals'" :refUser="this.refId" :showUserName="true" :userName="this.userName"></professionals-form>
                    <referals-form v-if="this.type == 'referals'" :refUser="this.refId" :showUserName="true" :userName="this.userName"></referals-form>
                </div>
            </div>

            
        </div>
    </div>
</template>
<script>
import publicationsForm from '@/components/admin-reports/publications-form.vue'
import publicationsWorkshopForm from '@/components/admin-reports/publications-workshop-form.vue'
import consultantsForm from '@/components/admin-reports/consultants-form.vue'
import professionalsSalesForm from '@/components/admin-reports/professionals-sales-form.vue'
import professionalsSalesWorkshopForm from '@/components/admin-reports/professionals-sales-workshop-form.vue'
import professionalsForm from '@/components/admin-reports/professionals-form.vue'
import referalsForm from '@/components/admin-reports/referals-form.vue'
export default {
    props: ['openHelp', 'refId', 'type', 'userName', 'mode', 'publishRef'],
    data(){
        return{  
            title: '',
            menuText: 'Ver Índice',
            full: 'full',
            maximize: '',
            maximizeIcon: 'la-expand',
            pageCount: 1,
            isLoading: true,
            scales: 1, 
            pages: 1,
            pdf: '',
            vimeoID: '774027404',
            doNotShow: false,
            disabledNext: true,
            disabledPrev: true,
            play: false
        }
    },
    components: {
        publicationsForm,
        publicationsWorkshopForm,
        consultantsForm,
        professionalsSalesForm,
        professionalsSalesWorkshopForm,
        professionalsForm,
        referalsForm
    },
    mounted: function () { 
        if(this.type == 'publications'){
            this.title = "Publicaciones Terapias";
        }
        if(this.type == 'publications-workshop'){
            this.title = "Publicaciones Talleres";
        }      
        if(this.type == 'consultants'){
            this.title = "Contactos";
        }
        if(this.type == 'sales'){
            this.title = "Contrataciones Terapias";
        }
        if(this.type == 'sales-workshop'){
            this.title = "Contrataciones Talleres";
        }
        if(this.type == 'professionals'){
            this.title = "Profesionales";
        }
        if(this.type == 'referals'){
            this.title = "Referidos";
        }
        this.dragElement(document.getElementById("myHelp")); 
    },  
    methods: {
        closeModal(){
            this.showModal = false;
            this.$root.closeModal();
        }, 
        maxMin(){
            if(this.maximize == ''){
                this.maximize = 'maximize';
                this.maximizeIcon = 'la-compress';     
            }else{
                this.maximize = '';
                this.maximizeIcon = 'la-expand';
            }
        },
        dragElement(elmnt) {
                var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
                if (document.getElementById(elmnt.id + "header")) {
                    // if present, the header is where you move the DIV from:
                    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
                } else {
                    // otherwise, move the DIV from anywhere inside the DIV:
                    elmnt.onmousedown = dragMouseDown;
                }
                function dragMouseDown(e) {
                    e = e || window.event;
                    e.preventDefault();
                    // get the mouse cursor position at startup:
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                    document.onmouseup = closeDragElement;
                    // call a function whenever the cursor moves:
                    document.onmousemove = elementDrag;
                }
                function elementDrag(e) {
                    e = e || window.event;
                    e.preventDefault();
                    // calculate the new cursor position:
                    pos1 = pos3 - e.clientX;
                    pos2 = pos4 - e.clientY;
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                    // set the element's new position:
                    if((elmnt.offsetTop - pos2) < 0){
                        elmnt.style.top =  "0px";
                    }else if((elmnt.offsetTop - pos2) > (window.innerHeight - 100)){
                       elmnt.style.top = (window.innerHeight - 100) + "px";
                    }else{
                        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                    }
                    if((elmnt.offsetLeft - pos1) > (window.innerWidth - 100)){
                        elmnt.style.left = (window.innerWidth - 100) + "px";
                    }else if((elmnt.offsetLeft - pos1) < -880){

                    }else{
                        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
                    }
                    
                }
                function closeDragElement() {
                    // stop moving when mouse button is released:
                    document.onmouseup = null;
                    document.onmousemove = null;
                }
           
        },
    
    }
}
</script>