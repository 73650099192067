<template>
    <div class="row" id="basic-table">
        <div class="col-12">
            <div class="card" :class="fixedfullscreen">
                <div class="card-header d-none d-md-block">
                    <h4 class="card-title">Mostrando los últimos {{showRecordsDay}} días <span v-if="showUserName">de <b>{{userName}}</b></span></h4>
                    <p v-if="records.length > 0"><b>Total: {{records.length}} resultados </b></p>
                </div>
                <div class="mb-2">
                    <div class="card-text">      
                        <div class="row justify-content-end align-items-center">
                            <div class="col-md-3 mt-1"> 
                                <div class="form-check form-check-inline d-block m-1">
                                    <input class="form-check-input" type="checkbox" id="column1" value="checked" :checked="tipeSearch" @click="changeSearchType()">
                                    <label class="form-check-label" for="column1">Buscar por fecha</label>
                                </div>
                            </div>
                            <div class="col-md-4 mt-1"> 
                                <select v-if="!tipeSearch" class="form-select" v-model="showRecordsDay" @change="findRecord()">
                                    <option value="7">Últimos 7 días</option>
                                    <option value="15">Últimos 15 días</option>
                                    <option value="30">Últimos 30 días</option>
                                    <option value="60">Últimos 60 días</option>
                                    <option value="90">Últimos 90 días</option>
                                    <option value="365">Últimos 365 días</option>
                                    <option value="3650">Mostrar todos</option>
                                </select> 
                                <div v-if="tipeSearch" class="input-group form-password-toggle">   
                                    <input v-if="tipeSearch" type="date" class="form-control" placeholder="" v-model="dateSearch">
                                    <span class="input-group-text cursor-pointer"><i class="las la-search" @click="searchDate()"></i></span>
                                </div>


                                
                            </div>
                            <div class="col-9 col-md-4 mt-1">
                                <div class="d-flex w-100 justify-content-start align-items-center">
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow py-0 waves-effect waves-float waves-light" data-bs-toggle="dropdown">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                            Mostrar Columnas
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end" style="width:350px">            
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column1" value="checked" :checked="column1" @click="showHideColum(1)">
                                                <label class="form-check-label" for="column1">Título de la publicación</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column2" value="checked" :checked="column2" @click="showHideColum(2)">
                                                <label class="form-check-label" for="column2">Nombre del profesional</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column3" value="checked" :checked="column3" @click="showHideColum(3)">
                                                <label class="form-check-label" for="column3">Cantidad de fotos</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column4" value="checked" :checked="column4" @click="showHideColum(4)">
                                                <label class="form-check-label" for="column4">Incluye video</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column5" value="checked" :checked="column5" @click="showHideColum(5)">
                                                <label class="form-check-label" for="column5">Tipo</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column6" value="checked" :checked="column6" @click="showHideColum(6)">
                                                <label class="form-check-label" for="column6">Duración</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column7" value="checked" :checked="column7" @click="showHideColum(7)">
                                                <label class="form-check-label" for="column7">Grupal</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column8" value="checked" :checked="column8" @click="showHideColum(8)">
                                                <label class="form-check-label" for="column8">Cantidad de turnos asignados</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column9" value="checked" :checked="column9" @click="showHideColum(9)">
                                                <label class="form-check-label" for="column9">Precio regular local</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column10" value="checked" :checked="column10" @click="showHideColum(10)">
                                                <label class="form-check-label" for="column10">Precio regular Internacional</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column11" value="checked" :checked="column11" @click="showHideColum(11)">
                                                <label class="form-check-label" for="column11">Precio pra. consulta local</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column12" value="checked" :checked="column12" @click="showHideColum(12)">
                                                <label class="form-check-label" for="column12">Precio pra. consulta internacional</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column13" value="checked" :checked="column13" @click="showHideColum(13)">
                                                <label class="form-check-label" for="column13">Precio promocional local</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column14" value="checked" :checked="column14" @click="showHideColum(14)">
                                                <label class="form-check-label" for="column14">Precio promocional internacional</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column15" value="checked" :checked="column15" @click="showHideColum(15)">
                                                <label class="form-check-label" for="column15">Pausada por el usuario</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column16" value="checked" :checked="column16" @click="showHideColum(16)">
                                                <label class="form-check-label" for="column16">Pausada por Animasana</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column20" value="checked" :checked="column17" @click="showHideColum(20)">
                                                <label class="form-check-label" for="column20">Total ventas</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column17" value="checked" :checked="column17" @click="showHideColum(17)">
                                                <label class="form-check-label" for="column17">Total ventas en Animasana</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column18" value="checked" :checked="column18" @click="showHideColum(18)">
                                                <label class="form-check-label" for="column18">Total ventas en Landing</label>
                                            </div>
                                            <div class="form-check form-check-inline d-block m-1">
                                                <input class="form-check-input" type="checkbox" id="column19" value="checked" :checked="column19" @click="showHideColum(19)">
                                                <label class="form-check-label" for="column19">Link</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 col-md-1 mt-1">
                                <div class="d-flex justify-content-end align-items-center">
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-outline-primary dropdown-toggle hide-arrow waves-effect waves-float waves-light" @click="exportOption()">
                                            <i class="las la-file-csv"></i> Exportar
                                        </button>
                                    </div>
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow py-0 waves-effect waves-float waves-light" @click="maximize()">
                                            <i class="las fs-30" :class="spandIcon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="table-responsive h-100">
                    <table class="table table-striped position-relative">
                        <thead class="fixed-head-table">
                            <tr>
                                <th colspan="1" class="text-center bg-my"></th>
                                <th v-if="title1 > 0" :colspan="title1" class="text-center bg-light-success">INFORMACIÓN</th>
                                <th v-if="title2 > 0" :colspan="title2" class="text-center bg-light-warning">PRECIO REGULAR</th>
                                <th v-if="title3 > 0" :colspan="title3" class="text-center bg-light-warning">PRECIO PRA. CONSULTA</th>
                                <th v-if="title4 > 0" :colspan="title4" class="text-center bg-light-warning">PRECIO PROMOCIONAL</th>
                                <th v-if="column15" colspan="1" class="text-center bg-light-primary"><h4 class="m-0 text-primary" title="Pausadas por el usuario"><i class="lar la-user"></i></h4></th>
                                <th v-if="column16" colspan="1" class="text-center bg-light-primary"><h4 class="m-0 text-primary" title="Pausadas por Animasana"><i class="las la-user-cog"></i></h4></th>
                                <th v-if="title5 > 0" :colspan="title5" class="text-center bg-light-info">VENTAS</th>
                                <th v-if="column19" colspan="1" class="text-center bg-light-info"></th>
                                <th colspan="1" class="text-center bg-light-info"></th>
                            </tr>
                            <tr>
                                <th class="text-center bg-my text-success sticky"><div class="d-flex align-items-center justify-content-center">Terapia<span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('therapy')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('therapy')"></i></span></div></th>
                                <th v-if="column1" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Título<span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('title')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('title')"></i></span></div></th>
                                <th v-if="column2" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Profesional <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('userFirstName')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('userFirstName')"></i></span></div></th>
                                <th v-if="column3" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Fotos <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('imgQty')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('imgQty')"></i></span></div></th>
                                <th v-if="column4" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Videos <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('hasVideo')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('hasVideo')"></i></span></div></th>
                                <th v-if="column5" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Tipo <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('type')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('type')"></i></span></div></th>
                                <th v-if="column6" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Duración <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('duration')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('duration')"></i></span></div></th>
                                <th v-if="column7" class="text-center text-success"><div class="d-flex align-items-center justify-content-center">Grupal <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('maxpeoples')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('maxpeoples')"></i></span></div></th>
                                <th v-if="column8" class="text-center text-success"><div class="d-flex align-items-center justify-content-center" title="Disponibilidad de turnos semanales">Turnos <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('turnsQty')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('turnsQty')"></i></span></div></th>
                                <th v-if="column9" class="text-center text-warning"><div class="d-flex align-items-center justify-content-center">Local <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('realPriceLocal')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('realPriceLocal')"></i></span></div></th>
                                <th v-if="column10" class="text-center text-warning"><div class="d-flex align-items-center justify-content-center">Internacional <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('realPriceInter')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('realPriceInter')"></i></span></div></th>
                                <th v-if="column11" class="text-center text-warning"><div class="d-flex align-items-center justify-content-center">Local <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('firstPriceLocal')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('firstPriceLocal')"></i></span></div></th>
                                <th v-if="column12" class="text-center text-warning"><div class="d-flex align-items-center justify-content-center">Internacional <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('firsPriceInter')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('firsPriceInter')"></i></span></div></th>
                                <th v-if="column13" class="text-center text-warning"><div class="d-flex align-items-center justify-content-center">Local <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('promoPriceLocal')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('promoPriceLocal')"></i></span></div></th>
                                <th v-if="column14" class="text-center text-warning"><div class="d-flex align-items-center justify-content-center">Internacional <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('promoPriceInter')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('promoPriceInter')"></i></span></div></th>
                                <th v-if="column15" class="text-center text-primary"><div class="d-flex align-items-center justify-content-center">Pausada <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('pauseUser')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('pauseUser')"></i></span></div></th>
                                <th v-if="column16" class="text-center text-primary"><div class="d-flex align-items-center justify-content-center">Pausada <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('pauseAdmin')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('pauseAdmin')"></i></span></div></th>
                                <th v-if="column20" class="text-center text-info"><div class="d-flex align-items-center justify-content-center">Totales <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalSales')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalSales')"></i></span></div></th>
                                <th v-if="column17" class="text-center text-info"><div class="d-flex align-items-center justify-content-center">Animasana <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalSalesAnim')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalSalesAnim')"></i></span></div></th>
                                <th v-if="column18" class="text-center text-info"><div class="d-flex align-items-center justify-content-center">Landing <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalSalesLanding')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalSalesLanding')"></i></span></div></th>
                                <th v-if="column19" class="text-center text-info"><div class="d-flex align-items-center justify-content-center">Link</div></th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="b in records" :key="b.id"> 
                                <td class="sticky">
                                    <div class="d-flex align-items-center" style="width: 250px;" v-if="b.therapyOther == ''">{{b.therapy}}</div>
                                    <div class="d-flex align-items-center" style="width: 250px;" v-if="b.therapyOther != ''">Otra - {{b.therapyOther}}</div>
                                </td>
                                <td v-if="column1">
                                    <div class="d-flex align-items-center" style="width: 160px;">
                                    {{b.title}}
                                    </div>
                                </td>
                                <td v-if="column2">
                                    <div style="width: 250px;" class="d-flex align-items-center justify-content-center"> 
                                        <span class="fw-bold m-1">{{b.userFirstName}} {{b.userLastName}}</span> 
                                    </div>   
                                </td>
                                <td v-if="column3" class="text-center"><span class="badge rounded-pill badge-light-success me-1">{{b.imgQty}}</span></td>
                                <td v-if="column4 && b.hasVideo == 1" class="text-center">SI</td>
                                <td v-if="column4 && b.hasVideo == 0" class="text-center">NO</td>
                                <td v-if="column5 && b.type == 1" class="text-center"><span class="badge rounded-pill badge-light-success">Online</span></td>
                                <td v-if="column5 && b.type == 2" class="text-center"><span class="badge rounded-pill badge-light-success">Presencial</span></td>
                                <td v-if="column6" class="text-center"><span class="badge rounded-pill badge-light-success me-1">{{b.duration}}min.</span></td>
                                <td v-if="column7 && b.maxpeoples > 1" class="text-center">SI</td>
                                <td v-if="column7 && (b.maxpeoples == 0 || b.maxpeoples == 1)" class="text-center">NO</td>
                                <td v-if="column8" class="text-center"><span class="badge rounded-pill badge-light-success me-1" title="Disponibilidad de turnos semanales">{{b.turnsQty}}</span></td>
                                <td v-if="column9 && b.free == 0" class="text-end">{{b.currencyLocal}} {{b.realPriceLocal}}</td>
                                <td v-if="column10 && b.free == 0" class="text-end">{{b.currencyGlobal}} {{b.realPriceInter}}</td>
                                <td v-if="column9 && b.free == 1" class="text-end"><span class="badge rounded-pill badge-light-warning">Gratis</span></td>
                                <td v-if="column10 && b.free == 1" class="text-end"><span class="badge rounded-pill badge-light-warning">Gratis</span></td>
                                <td v-if="column11 && b.firstPriceLocal != 0" class="text-end">{{b.currencyLocal}} {{b.firstPriceLocal}}</td>
                                <td v-if="column12 && b.firsPriceInter != 0" class="text-end">{{b.currencyGlobal}} {{b.firsPriceInter}}</td>     
                                <td v-if="column11 && b.firstPriceLocal == 0" class="text-end">---</td>
                                <td v-if="column12 && b.firsPriceInter == 0" class="text-end">---</td>
                                <td v-if="column13 && b.promoPriceLocal != 0" class="text-end">{{b.currencyLocal}} {{b.promoPriceLocal}}</td>
                                <td v-if="column14 && b.promoPriceInter != 0" class="text-end">{{b.currencyGlobal}} {{b.promoPriceInter}}</td>
                                <td v-if="column13 && b.promoPriceLocal == 0" class="text-end">---</td>
                                <td v-if="column14 && b.promoPriceInter == 0" class="text-end">---</td>

                                <td v-if="column15 && b.pauseUser == 1" class="text-center">SI</td>
                                <td v-if="column15 && b.pauseUser == 0" class="text-center">NO</td>
                                <td v-if="column16 && b.pauseAdmin == 1" class="text-center">SI</td>
                                <td v-if="column16 && b.pauseAdmin == 0" class="text-center">NO</td>
                                <td v-if="column20" class="text-center"><span class="badge rounded-pill badge-light-info me-1 pointer" @click="expandInfo('sales', b.userCode, b.userFirstName+' '+b.userLastName, '1', b.linkId)"><i class="las la-info"></i> {{b.totalSales}}</span></td>
                                <td v-if="column17" class="text-center"><span class="badge rounded-pill badge-light-info me-1">{{b.totalSalesAnim}}</span></td>
                                <td v-if="column18" class="text-center"><span class="badge rounded-pill badge-light-info me-1">{{b.totalSalesLanding}}</span></td>
                                <td v-if="column19" class="text-center"><a :href="'https://animasana.net/ar/es/service/'+b.linkId+'/'+b.link" target="blank" class="">Ver</a></td>
                                <td>
                                    
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="tableSearch">
                    <div class="input-group form-password-toggle">   
                        <input type="text" class="form-control" placeholder="Buscar dentro de los resultados por nombre o apellido del profesional, titulo, terapia, moneda" v-model="textSearch" @keyup="findinArray()" >
                        <span class="input-group-text cursor-pointer"><i class="las la-search" @change="findinArray()"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {  
    name: "publications-form",
    props: ['refUser', 'showUserName', 'userName'],
    data(){
        return{
            records: [],
            recordsBkp: [],
            column1: true,
            column2: true,
            column3: true,
            column4: true,
            column5: true,
            column6: true,
            column7: true,
            column8: true,
            column9: true,
            column10: true,
            column11: true,
            column12: true,
            column13: true,
            column14: true,
            column15: true,
            column16: true,
            column17: true,
            column18: true,
            column19: true,
            column20: true,
            title1: 8,
            title2: 2,
            title3: 2,
            title4: 2,
            title5: 3,
            fixedfullscreen: '',
            spandIcon: 'la-expand-arrows-alt',
            showRecordsDay: 30,
            tipeSearch: false,
            dateSearch: '',
            exportArray: [],
            export: false,
            refId: '',
            adminType: 0
        }
    },
    mounted: function () {  
        var data = JSON.parse(localStorage.getItem('userData'));
        this.adminType = data.userType;
        if(this.refUser != ''){
            this.refId = this.refUser;
            this.showRecordsDay = 90;
        }
        this.findRecord();
    },
    methods:{
        searchDate(){
            var timestamp = new Date(this.dateSearch);
            timestamp = timestamp.getTime();
            if(isNaN(timestamp) == false){
                this.findRecord();
            }
            
        },
        changeSearchType(){
            if(this.tipeSearch == false){
                this.tipeSearch = true;
                this.dateSearch = '';
            }else{
                this.tipeSearch = false;
                this.dateSearch = '';
                this.findRecord();
            }
        },
        shortDateASC(val){
            if(val == 'regDate'){
                this.records.sort((a, b) => Number(new Date(b.regDateNoFormat)) - Number(new Date(a.regDateNoFormat)));
            }    
        },
        shortDateDESC(val){
            if(val == 'regDate'){
                this.records.sort((a, b) => Number(new Date(a.regDateNoFormat)) - Number(new Date(b.regDateNoFormat)));
            }    
        },
        shortNumbersASC(val){
            // sort by value
            if(val == 'maxpeoples'){
                this.records.sort((a, b) => a.maxpeoples - b.maxpeoples);
            }
            if(val == 'imgQty'){
                this.records.sort((a, b) => a.imgQty - b.imgQty);
            }
            if(val == 'hasVideo'){
                this.records.sort((a, b) => a.hasVideo - b.hasVideo);
            }
            if(val == 'type'){
                this.records.sort((a, b) => a.type - b.type);
            }
            if(val == 'duration'){
                this.records.sort((a, b) => a.duration - b.duration);
            }  
            if(val == 'turnsQty'){
                this.records.sort((a, b) => a.turnsQty - b.turnsQty);
            }
            if(val == 'realPriceLocal'){
                this.records.sort((a, b) => a.realPriceLocal - b.realPriceLocal);
            }
            if(val == 'realPriceInter'){
                this.records.sort((a, b) => a.realPriceInter - b.realPriceInter);
            }
            if(val == 'firstPriceLocal'){
                this.records.sort((a, b) => a.firstPriceLocal - b.firstPriceLocal);
            }
            if(val == 'firsPriceInter'){
                this.records.sort((a, b) => a.firsPriceInter - b.firsPriceInter);
            }
            if(val == 'promoPriceLocal'){
                this.records.sort((a, b) => a.promoPriceLocal - b.promoPriceLocal);
            }
            if(val == 'promoPriceInter'){
                this.records.sort((a, b) => a.promoPriceInter - b.promoPriceInter);
            }
            if(val == 'pauseUser'){
                this.records.sort((a, b) => a.pauseUser - b.pauseUser);
            }
            if(val == 'pauseAdmin'){
                this.records.sort((a, b) => a.pauseAdmin - b.pauseAdmin);
            }
            if(val == 'totalSales'){
                this.records.sort((a, b) => a.totalSales - b.totalSales);
            }
            if(val == 'totalSalesAnim'){
                this.records.sort((a, b) => a.totalSalesAnim - b.totalSalesAnim);
            }
            if(val == 'totalSalesLanding'){
                this.records.sort((a, b) => a.totalSalesLanding - b.totalSalesLanding);
            }
        },
        shortNumbersDESC(val){
            // sort by value
            if(val == 'maxpeoples'){
                this.records.sort((a, b) => b.maxpeoples - a.maxpeoples);
            }
            if(val == 'imgQty'){
                this.records.sort((a, b) => b.imgQty - a.imgQty);
            }
            if(val == 'hasVideo'){
                this.records.sort((a, b) => b.hasVideo - a.hasVideo);
            }
            if(val == 'type'){
                this.records.sort((a, b) => b.type - a.type);
            }
            if(val == 'duration'){
                this.records.sort((a, b) => b.duration - a.duration);
            }
            if(val == 'turnsQty'){
                this.records.sort((a, b) => b.turnsQty - a.turnsQty);
            }
            if(val == 'realPriceLocal'){
                this.records.sort((a, b) => b.realPriceLocal - a.realPriceLocal);
            }
            if(val == 'realPriceInter'){
                this.records.sort((a, b) => b.realPriceInter - a.realPriceInter);
            }
            if(val == 'firstPriceLocal'){
                this.records.sort((a, b) => b.firstPriceLocal - a.firstPriceLocal);
            }
            if(val == 'firsPriceInter'){
                this.records.sort((a, b) => b.firsPriceInter - a.firsPriceInter);
            }
            if(val == 'promoPriceLocal'){
                this.records.sort((a, b) => b.promoPriceLocal - a.promoPriceLocal);
            }
            if(val == 'promoPriceInter'){
                this.records.sort((a, b) => b.promoPriceInter - a.promoPriceInter);
            }
            if(val == 'pauseUser'){
                this.records.sort((a, b) => b.pauseUser - a.pauseUser);
            }
            if(val == 'pauseAdmin'){
                this.records.sort((a, b) => b.pauseAdmin - a.pauseAdmin);
            }
            if(val == 'totalSales'){
                this.records.sort((a, b) => b.totalSales - a.totalSales);
            }
            if(val == 'totalSalesAnim'){
                this.records.sort((a, b) => b.totalSalesAnim - a.totalSalesAnim);
            }
            if(val == 'totalSalesLanding'){
                this.records.sort((a, b) => b.totalSalesLanding - a.totalSalesLanding);
            }
        },
        shortWordsASC(val){
            // sort by name
            this.records.sort((a, b) => {
                let nameA;
                let nameB;
            if(val == 'therapy'){
                nameA = a.therapy.toUpperCase(); // ignore upper and lowercase
                nameB = b.therapy.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'title'){
                nameA = a.title.toUpperCase(); // ignore upper and lowercase
                nameB = b.title.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'userFirstName'){
                nameA = a.userFirstName.toUpperCase(); // ignore upper and lowercase
                nameB = b.userFirstName.toUpperCase(); // ignore upper and lowercase
            }
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
            });

        },
        shortWordsDESC(val){
            // sort by name
            this.records.sort((a, b) => {
                let nameA;
                let nameB;
            if(val == 'therapy'){
                nameA = a.therapy.toUpperCase(); // ignore upper and lowercase
                nameB = b.therapy.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'title'){
                nameA = a.title.toUpperCase(); // ignore upper and lowercase
                nameB = b.title.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'userFirstName'){
                nameA = a.userFirstName.toUpperCase(); // ignore upper and lowercase
                nameB = b.userFirstName.toUpperCase(); // ignore upper and lowercase
            }
            if (nameA > nameB) {
                return -1;
            }
            if (nameA < nameB) {
                return 1;
            }

            // names must be equal
            return 0;
            });
        },
        maximize(){
            if(this.fixedfullscreen == ""){
                this.fixedfullscreen = "fixed-full-screen";
                this.spandIcon = "la-compress-arrows-alt";
            }else{
                this.fixedfullscreen = "";
                this.spandIcon = "la-expand-arrows-alt";
            }
        },
        showHideColum(val){
            if(val == 1){
                if(this.column1 == true){
                    this.column1 = false;
                    this.title1--;
                }else{
                    this.column1 = true;
                    this.title1++;
                }
            }else if(val == 2){
                if(this.column2 == true){
                    this.column2 = false;
                    this.title1--;
                }else{
                    this.column2 = true;
                    this.title1++;
                }
            }else if(val == 3){
                if(this.column3 == true){
                    this.column3 = false;
                    this.title1--;
                }else{
                    this.column3 = true;
                    this.title1++;
                }
            }else if(val == 4){
                if(this.column4 == true){
                    this.column4 = false;
                    this.title1--;
                }else{
                    this.column4 = true;
                    this.title1++;
                }
            }else if(val == 5){
                if(this.column5 == true){
                    this.column5 = false;
                    this.title1--;
                }else{
                    this.column5 = true;
                    this.title1++;
                }
            }else if(val == 6){
                if(this.column6 == true){
                    this.column6 = false;
                    this.title1--;
                }else{
                    this.column6 = true;
                    this.title1++;
                }
            }else if(val == 7){
                if(this.column7 == true){
                    this.column7 = false;
                    this.title1--;
                }else{
                    this.column7 = true;
                    this.title1++;
                }
            }else if(val == 8){
                if(this.column8 == true){
                    this.column8 = false;
                    this.title1--;
                }else{
                    this.column8 = true;
                    this.title1++;
                }
            }else if(val == 9){
                if(this.column9 == true){
                    this.column9 = false;
                    this.title2--;
                }else{
                    this.column9 = true;
                    this.title2++;
                }
            }else if(val == 10){
                if(this.column10 == true){
                    this.column10 = false;
                    this.title2--;
                }else{
                    this.column10 = true;
                    this.title2++;
                }
            }else if(val == 11){
                if(this.column11 == true){
                    this.column11 = false;
                    this.title3--;
                }else{
                    this.column11 = true;
                    this.title3++;
                }
            }else if(val == 12){
                if(this.column12 == true){
                    this.column12 = false;
                    this.title3--;
                }else{
                    this.column12 = true;
                    this.title3++;
                }
            }else if(val == 13){
                if(this.column13 == true){
                    this.column13 = false;
                    this.title4--;
                }else{
                    this.column13 = true;
                    this.title4++;
                }
            }else if(val == 14){
                if(this.column14 == true){
                    this.column14 = false;
                    this.title4--;
                }else{
                    this.column14 = true;
                    this.title4++;
                }
            }else if(val == 15){
                if(this.column15 == true){
                    this.column15 = false;
                }else{
                    this.column15 = true;
                }
            }else if(val == 16){
                if(this.column16 == true){
                    this.column16 = false;
                }else{
                    this.column16 = true;
                }
            }else if(val == 17){
                if(this.column17 == true){
                    this.column17 = false;
                     this.title5--;
                }else{
                    this.column17 = true;
                    this.title5++;
                } 
            }else if(val == 18){
                if(this.column18 == true){
                    this.column18 = false;
                     this.title5--;
                }else{
                    this.column18 = true;
                    this.title5++;
                }
            }else if(val == 19){
                if(this.column19 == true){
                    this.column19 = false;
                     this.title5--;
                }else{
                    this.column19 = true;
                     this.title5++;
                }
            }else if(val == 20){
                if(this.column20 == true){
                    this.column20 = false;
                     this.title5--;
                }else{
                    this.column20 = true;
                     this.title5++;
                }
            }
        },
        exportOption(){
            this.export = true;
            this.findRecord();
        },
        download(dataurl, filename) {
            const link = document.createElement("a");
            link.href = dataurl;
            link.download = filename;
            link.click();
        },
        async findRecord(){ 
            if(this.export == true){
                this.exportArray = this.records;
            }else{
                this.exportArray = '';
                this.records = [];
            }
            var payload = {
                action: 'admin-get-publications-reports',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                show: this.showRecordsDay,
                dateSearch: this.dateSearch,
                exportArray: this.exportArray,
                ref: this.refId
            }
            await axios.post('/index.php', payload).then(result => {
                if(this.export == true){
                    if(window.location.host == 'back.animasana.net'){
                        this.download('https://api.animasana.net' + result.data.file, 'nombre.xlsx');  
                    }else if(window.location.host == 'api-qa.animasana.net'){
                        this.download('https://api-qa.animasana.net' + result.data.file, 'nombre.xlsx');  
                    }else{
                       this.download('https://api-qa.animasana.net' + result.data.file, 'nombre.xlsx'); 
                    }
                }else{
                    this.records = result.data; 
                    this.recordsBkp = result.data; 
                }
                this.export = false;
            })
            .catch(error => {
                return;
            })  
        },
        findinArray(){
            var matches = this.recordsBkp;
            this.records = matches.filter(
                s => s.userFirstName.toLowerCase().includes(this.textSearch.toLowerCase()) 
                || s.userLastName.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.title.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.therapy.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.therapyOther.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.currencyLocal.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.currencyGlobal.toLowerCase().includes(this.textSearch.toLowerCase())
            );
        },
        expandInfo(val, userId, userName, mode, publishRef){
            this.$root.expandInfo(val, userId, userName, mode, publishRef);
        }
     }
}
</script>
<style scoped>
.tableSearch{
    position: sticky;
    z-index: 9;
    bottom: 15px;
    width: 40%;
    background: #6e6b7bbd;
    padding: 10px;
    margin: auto;
    border-radius: 5px;
}
.fixed-full-screen .tableSearch{
position: fixed;
    bottom: 48px;
    left: 0;
    right: 0;
    }
@media (max-width: 600px) {
    .tableSearch{
    width: 95%;
    }
}
@media (min-width: 600px) {
th.bg-my{
    position:sticky;
  left:0px;
  z-index: 2;
}
td.sticky
{
  position:sticky;
  left:0px;
  z-index: 2;
  background: #fff;
}
.dark-layout td.sticky
{
  position:sticky;
  left:0px;
  z-index: 2;
  background: #283046;
}
tr.sticky:not(caption) > *{
    box-shadow: inset 0 0 0 9999px #545454;
}

.dark-layout tr.sticky:not(caption) > *{
    box-shadow: inset 0 0 0 9999px #545454;
}
}
.fixed-full-screen{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 5px;
    width: 100%;
    height: 100%;
    background: #f7f7f7;
    overflow: auto;
    right: 0;
    padding: 10px;
}
.dark-layout .fixed-full-screen{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 5px;
    width: 100%;
    height: 100%;
    background: #161d31;
    overflow: auto;
    right: 0;
    padding: 10px;
}
.pointer{
    cursor: pointer;
}
.fixed-head-table{
    position: sticky;
    top: 0;
    background: #343d55;
    z-index: 4;
}
.dark-layout .fixed-head-table{
    position: sticky;
    top: 0;
    background: #343d55;
    z-index: 4;
}
.arrow-short{
    margin-left: 10px;
}
.fs-30{
    font-size: 30px;
}
</style>