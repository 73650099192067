import { createRouter, createWebHistory } from 'vue-router'
import startLocation from '../views/startLocation.vue'

const routes = [
  {
    path: '/:browserCountry/:userLang/dashboard',
    name: 'dashboard',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/dashboard.vue')
  },
  {
    path: '/:browserCountry/:userLang/professionals-reports',
    name: 'professionals-reports',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/admin-reports/professionals-reports.vue')
  },
  {
    path: '/:browserCountry/:userLang/consultants-reports',
    name: 'consultants-reports',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/admin-reports/consultants-reports.vue')
  },
  {
    path: '/:browserCountry/:userLang/recruiters-reports',
    name: 'recruiters-reports',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/admin-reports/recruiters-reports.vue')
  },
  {
    path: '/:browserCountry/:userLang/service-publications-reports',
    name: 'service-publications-reports',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/admin-reports/service-publications-reports.vue')
  },
  {
    path: '/:browserCountry/:userLang/workshop-publications-reports',
    name: 'workshop-publications-reports',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/admin-reports/workshop-publications-reports.vue')
  },
  {
    path: '/:browserCountry/:userLang/referals-reports',
    name: 'referals-reports',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/admin-reports/referals-reports.vue')
  },
  {
    path: '/:browserCountry/:userLang/professionals-sales/:ref?',
    name: 'professionals-sales',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/admin-reports/professionals-sales.vue')
  },
  {
    path: '/:browserCountry/:userLang/professionals-sales-workshops/:ref?',
    name: 'professionals-sales-workshops',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/admin-reports/professionals-sales-workshops.vue')
  },
  {
    path: '/:browserCountry/:userLang/subscription-payment',
    name: 'subscription-payment',
    component: () => import(/* wbpackChunkName "my-tools" */ '../views/admin-reports/subscription-payment.vue')
  },
  {
    path: '/:browserCountry/:userLang/',
    name: 'homeLang',
    component: () => import(/* wbpackChunkName "login" */ '../views/HomeView.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-profile',
    name: 'my-profile',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-profile-admin.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-info',
    name: 'change-user-info',
    component: () => import(/* wbpackChunkName "change-user-info" */ '../views/my-account/change-user-info-admin.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-email',
    name: 'change-user-email',
    component: () => import(/* wbpackChunkName "change-user-email" */ '../views/my-account/change-user-email-admin.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-password/:ref?',
    name: 'change-password',
    component: () => import(/* wbpackChunkName "change-password" */ '../views/my-account/change-password-admin.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-phone',
    name: 'change-user-phone',
    component: () => import(/* wbpackChunkName "change-user-phone" */ '../views/my-account/change-user-phone-admin.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-avatar',
    name: 'change-avatar',
    component: () => import(/* wbpackChunkName "change-avatar" */ '../views/my-account/change-avatar-admin.vue')
  },
  {
    path: '/:browserCountry/:userLang/task',
    name: 'task',
    component: () => import(/* wbpackChunkName "change-avatar" */ '../views/dashboard-admin/task.vue')
  },
  {
    path: '/:browserCountry/:userLang/admins',
    name: 'admins',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/admins.vue')
  },
  {
    path: '/:browserCountry/:userLang/plans',
    name: 'plans',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/plans/admin-plans.vue')
  },
  {
    path: '/:browserCountry/:userLang/login',
    name: 'login',
    component: () => import(/* wbpackChunkName "login" */ '../views/admin-login.vue')
  },
  {
    path: '/:browserCountry/:userLang/user-account/:ref',
    name: 'user-account',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/user-account.vue')
  },
  {
    path: '/:browserCountry/:userLang/deleted-users',
    name: 'deleted-users',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/admin-reports/deleted-users.vue')
  },
  
  


  {
    path: '/',
    name: 'startLocation',
    component: startLocation
  },

  

  

  
  {
    path: '/:browserCountry/:userLang/recovery-account',
    name: 'recovery-account',
    component: () => import(/* wbpackChunkName "recovery-account" */ '../views/recovery-account.vue')
  },
  

  {
    path: '/:browserCountry/:userLang/change-logo-square',
    name: 'change-logo-square',
    component: () => import(/* wbpackChunkName "change-logo-square" */ '../views/my-account/change-logo-square.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-logo-rectangle',
    name: 'change-logo-rectangle',
    component: () => import(/* wbpackChunkName "change-logo-rectangle" */ '../views/my-account/change-logo-rectangle.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-sign',
    name: 'change-sign',
    component: () => import(/* wbpackChunkName "change-sign" */ '../views/my-account/change-sign.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-account/:ref?',
    name: 'my-account',
    component: () => import(/* wbpackChunkName "my-account" */ '../views/my-account.vue')
  },
  
  {
    path: '/:browserCountry/:userLang/change-user-address',
    name: 'change-user-address',
    component: () => import(/* wbpackChunkName "change-user-address" */ '../views/my-account/change-user-address.vue')
  },
  
  {
    path: '/:browserCountry/:userLang/payonner-account',
    name: 'change-user-payonner',
    component: () => import(/* wbpackChunkName "change-user-payonner" */ '../views/my-account/change-user-payonner.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-business-name',
    name: 'change-business-name',
    component: () => import(/* wbpackChunkName "change-business-name" */ '../views/my-account/change-business-name.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-bio',
    name: 'change-user-bio',
    component: () => import(/* wbpackChunkName "change-user-bio" */ '../views/my-account/change-user-bio.vue')
  },
  {
    path: '/:browserCountry/:userLang/verify-email/:ref',
    name: 'verify-email',
    component: () => import(/* wbpackChunkName "verify-email" */ '../views/verify-email.vue')
  },

  {
    path: '/:browserCountry/:userLang/change-nickname',
    name: 'change-nickname',
    component: () => import(/* wbpackChunkName "change-nickname" */ '../views/my-account/change-nickname.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-bank',
    name: 'change-user-bank',
    component: () => import(/* wbpackChunkName "change-user-bank" */ '../views/my-account/change-user-bank.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-global-payments/:ref?',
    name: 'change-global-payments',
    component: () => import(/* wbpackChunkName "change-global-payments" */ '../views/my-account/change-global-payments.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-local-payments/:ref?',
    name: 'change-local-payments',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/change-local-payments.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-user-certificate',
    name: 'change-user-certificate',
    component: () => import(/* wbpackChunkName "change-user-certificate" */ '../views/my-account/change-user-certificate.vue')
  },
  {
    path: '/:browserCountry/:userLang/change-calendar',
    name: 'change-calendar',
    component: () => import(/* wbpackChunkName "change-calendar" */ '../views/my-account/change-calendar.vue')
  },
  
  {
    path: '/:browserCountry/:userLang/biometric',
    name: 'biometric',
    component: () => import(/* wbpackChunkName "biometric" */ '../views/biometric/biometric.vue')
  },
  //edicion de servicios
  
  {
    path: '/:browserCountry/:userLang/general-service/:ref/:type?',
    name: 'general-service',
    component: () => import(/* wbpackChunkName "general-service" */ '../views/edit-service/general-service.vue')
  },
  {
    path: '/:browserCountry/:userLang/edit-service/:ref',
    name: 'edit-service',
    component: () => import(/* wbpackChunkName "edit-service" */ '../views/edit-service/edit-service.vue')
  },
  {
    path: '/:browserCountry/:userLang/edit-turns/:ref',
    name: 'edit-turns',
    component: () => import(/* wbpackChunkName "edit-turns" */ '../views/edit-service/edit-turns.vue')
  },
  {
    path: '/:browserCountry/:userLang/blocked-days',
    name: 'blocked-days',
    component: () => import(/* wbpackChunkName "blocked-days" */ '../views/edit-service/blocked-days.vue')
  },
  
  {
    path: '/:browserCountry/:userLang/service/:ref/:title?',
    name: 'service',
    component: () => import(/* wbpackChunkName "service" */ '../views/service.vue')
  },
  {
    path: '/:browserCountry/:userLang/service-landing/:ref/:title?',
    name: 'service-landing',
    component: () => import(/* wbpackChunkName "service" */ '../views/service-landing.vue')
  },
  {
    path: '/:browserCountry/:userLang/imgs-editor',
    name: 'imgs-editor',
    component: () => import(/* wbpackChunkName "imgs-editor" */ '../views/edit-service/imgs-editor.vue')
  },
  {
    path: '/:browserCountry/:userLang/questions',
    name: 'questions',
    component: () => import(/* wbpackChunkName "questions" */ '../views/questions/questions.vue')
  },
  {
    path: '/:browserCountry/:userLang/answers',
    name: 'answers',
    component: () => import(/* wbpackChunkName "answers" */ '../views/questions/answers.vue')
  },
  {
    path: '/:browserCountry/:userLang/success-question',
    name: 'success-question',
    component: () => import(/* wbpackChunkName "success-question" */ '../views/questions/success-question.vue')
  },
  {
    path: '/:browserCountry/:userLang/autologin/:ref/:go/:id?',
    name: 'autologin',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/autologin.vue')
  },
  {
    path: '/:browserCountry/:userLang/confirmation/:ref?/:idPay?',
    name: 'confirmation-cart',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/confirmation.vue')
  },
  {
    path: '/:browserCountry/:userLang/confirmation-landing/:ref?/:idPay?',
    name: 'confirmation-cart-landing',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/confirmation-landing.vue')
  },
  {
    path: '/:browserCountry/:userLang/success',
    name: 'cash-success',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/cash-success.vue')
  },
  {
    path: '/:browserCountry/:userLang/free-success',
    name: 'free-success',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/free-success.vue')
  },
  {
    path: '/:browserCountry/:userLang/pay-success',
    name: 'card-success',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/card-success.vue')
  },
  {
    path: '/:browserCountry/:userLang/refound',
    name: 'card-refound',
    component: () => import(/* wbpackChunkName "autologin" */ '../views/cart/card-refound.vue')
  },
  {
    path: '/:browserCountry/:userLang/bookings/:ref?/:idPay?',
    name: 'bookings',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/bookings/bookings.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-bookings/:ref?/:idPay?',
    name: 'my-bookings',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/bookings/my-bookings.vue')
  },
  {
    path: '/:browserCountry/:userLang/calificate/:ref',
    name: 'client-calification',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/califications/client-calification.vue')
  },
  {
    path: '/:browserCountry/:userLang/califications/:ref',
    name: 'seller-calification',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/califications/seller-calification.vue')
  },
  {
    path: '/:browserCountry/:userLang/cancelation/:ref',
    name: 'client-cancelation',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/cancelations/client-cancelation.vue')
  },
  {
    path: '/:browserCountry/:userLang/cancelations/:ref',
    name: 'seller-cancelation',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/cancelations/seller-cancelation.vue')
  },
  {
    path: '/:browserCountry/:userLang/remark/:ref',
    name: 'client-remark',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/cancelations/client-remark.vue')
  },
  {
    path: '/:browserCountry/:userLang/remarks/:ref',
    name: 'seller-remark',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/cancelations/seller-remark.vue')
  },
  {
    path: '/:browserCountry/:userLang/chat/:ref?',
    name: 'chat',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/chat/chat.vue')
  },
  {
    path: '/:browserCountry/:userLang/chats/:ref?',
    name: 'prof-chat',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/chat/prof-chat.vue')
  },
  {
    path: '/:browserCountry/:userLang/clients/:ref?',
    name: 'clients',
    component: () => import(/* wbpackChunkName "my-bookings" */ '../views/clients/clients.vue')
  },


  {
    path: '/:browserCountry/:userLang/assistant',
    name: 'assistant',
    component: () => import(/* wbpackChunkName "page-editor" */ '../views/assistant.vue')
  },
  {
    path: '/:browserCountry/:userLang/assistants',
    name: 'assistant-client',
    component: () => import(/* wbpackChunkName "page-editor" */ '../views/assistant-client.vue')
  },

  
 
  {
    path: '/:browserCountry/:userLang/professional/:ref',
    name: 'professional',
    component: () => import(/* wbpackChunkName "page-editor" */ '../views/professional.vue')
  },
  {
    path: '/:browserCountry/:userLang/room/:ref/:group?',
    name: 'room',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/room/room.vue')
  },
  {
    path: '/:browserCountry/:userLang/search/:type?/:ref?/:country?/:state?/:city?/:langEs?/:langEn?/:langPo?/:langFr?/:langIt?/:langAl?/:today?/:gender?',
    name: 'search',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/search/search.vue')
  },
  {
    path: '/:browserCountry/:userLang/terapias/:type?/:ref?/:country?/:state?/:city?/:langEs?/:langEn?/:langPo?/:langFr?/:langIt?/:langAl?/:today?/:gender?',
    name: 'busqueda',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/search/search.vue')
  },
  {
    path: '/:browserCountry/:userLang/sintomas/:type?/:ref?/:country?/:state?/:city?/:langEs?/:langEn?/:langPo?/:langFr?/:langIt?/:langAl?/:today?/:gender?',
    name: 'busqueda-sintomas',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/search/search.vue')
  },
  {
    path: '/:browserCountry/:userLang/getSellerPublish/:ref?',
    name: 'getSellerPublish',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/search/getSellerPublish.vue')
  },
  {
    path: '/:browserCountry/:userLang/billing',
    name: 'billing',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/billing.vue')
  },
  
  {
    path: '/:browserCountry/:userLang/my-services',
    name: 'my-services',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-services.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-business',
    name: 'my-business',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-business.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-coupons',
    name: 'my-coupons',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-coupons.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-workshops',
    name: 'my-workshops',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-workshops.vue')
  },
  {
    path: '/:browserCountry/:userLang/my-charges',
    name: 'my-charges',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/my-charges.vue')
  },
  
  {
    path: '/:browserCountry/:userLang/config',
    name: 'sidebar-start-mobile',
    component: () => import(/* wbpackChunkName "change-local-payments" */ '../views/my-account/sidebar-start-mobile.vue')
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
