<template>
    <div class="row" id="basic-table">
        <div class="col-12">
            <div class="card" :class="fixedfullscreen">
                <div class="card-header d-none d-md-block">
                    <h4 class="card-title">Mostrando los últimos {{showRecordsDay}} días <span v-if="showUserName">de <b>{{userName}}</b></span></h4>
                    <p v-if="records.length > 0"><b>Total: {{records.length}} resultados </b></p>
                </div>
                <div class="mb-2">
                    <div class="card-text">      
                        <div class="row justify-content-end align-items-center">
                            <div class="col-md-3 mt-1"> 
                                <div class="form-check form-check-inline d-block m-1">
                                    <input class="form-check-input" type="checkbox" id="column1" value="checked" :checked="tipeSearch" @click="changeSearchType()">
                                    <label class="form-check-label" for="column1">Buscar por fecha</label>
                                </div>   
                            </div>

                            <div class="col-md-2 mt-1"> 
                                <select v-if="!tipeSearch" class="form-select" v-model="showRecordsDay" @change="findRecord()">
                                    <option value="7">Últimos 7 días</option>
                                    <option value="15">Últimos 15 días</option>
                                    <option value="30">Últimos 30 días</option>
                                    <option value="60">Últimos 60 días</option>
                                    <option value="90">Últimos 90 días</option>
                                    <option value="365">Últimos 365 días</option>
                                    <option value="3650">Mostrar todos</option>
                                </select> 
                                <div v-if="tipeSearch" class="input-group form-password-toggle">   
                                    <input v-if="tipeSearch" type="date" class="form-control" placeholder="" v-model="dateSearch">
                                    <span class="input-group-text cursor-pointer"><i class="las la-search" @click="searchDate()"></i></span>
                                </div>   
                            </div>
                            <div class="col-9 col-md-3 mt-1">
                                <div class="d-flex w-100 justify-content-start align-items-center">
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow py-0 waves-effect waves-float waves-light" data-bs-toggle="dropdown">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                            Mostrar Columnas
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end" style="width:350px">   
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column1" value="checked" :checked="column1" @click="showHideColum(1)">
                                                        <label class="form-check-label" for="column1">Consultante</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column2" value="checked" :checked="column2" @click="showHideColum(2)">
                                                        <label class="form-check-label" for="column2">Título</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column3" value="checked" :checked="column3" @click="showHideColum(3)">
                                                        <label class="form-check-label" for="column3">Terapía</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column4" value="checked" :checked="column4" @click="showHideColum(4)">
                                                        <label class="form-check-label" for="column4">Fecha Consulta</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column5" value="checked" :checked="column5" @click="showHideColum(5)">
                                                        <label class="form-check-label" for="column5">Hora Consulta</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column6" value="checked" :checked="column6" @click="showHideColum(6)">
                                                        <label class="form-check-label" for="column6">Duración</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column20" value="checked" :checked="column20" @click="showHideColum(20)">
                                                        <label class="form-check-label" for="column20">Modalidad</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column21" value="checked" :checked="column21" @click="showHideColum(21)">
                                                        <label class="form-check-label" for="column21">Origén</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column23" value="checked" :checked="column23" @click="showHideColum(23)">
                                                        <label class="form-check-label" for="column23">Recurrente</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column7" value="checked" :checked="column7" @click="showHideColum(7)">
                                                        <label class="form-check-label" for="column7">Estado</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column8" value="checked" :checked="column8" @click="showHideColum(8)">
                                                        <label class="form-check-label" for="column8">Pendiente</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column9" value="checked" :checked="column9" @click="showHideColum(9)">
                                                        <label class="form-check-label" for="column9">Cobrado</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column10" value="checked" :checked="column10" @click="showHideColum(10)">
                                                        <label class="form-check-label" for="column10">Fecha de Cobro</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column11" value="checked" :checked="column11" @click="showHideColum(11)">
                                                        <label class="form-check-label" for="column11">ID Operación</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column12" value="checked" :checked="column12" @click="showHideColum(12)">
                                                        <label class="form-check-label" for="column12">ID de Pago</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column13" value="checked" :checked="column13" @click="showHideColum(13)">
                                                        <label class="form-check-label" for="column13">Pasarela</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column14" value="checked" :checked="column14" @click="showHideColum(14)">
                                                        <label class="form-check-label" for="column14">% Comisión</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column15" value="checked" :checked="column15" @click="showHideColum(15)">
                                                        <label class="form-check-label" for="column15">Total Comisión</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column16" value="checked" :checked="column16" @click="showHideColum(16)">
                                                        <label class="form-check-label" for="column16">Neto Profesional</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column17" value="checked" :checked="column17" @click="showHideColum(17)">
                                                        <label class="form-check-label" for="column17">Liberado</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column18" value="checked" :checked="column18" @click="showHideColum(18)">
                                                        <label class="form-check-label" for="column18">F. de Depósito</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column19" value="checked" :checked="column19" @click="showHideColum(19)">
                                                        <label class="form-check-label" for="column19">ID de Depósito</label>
                                                    </div>
                                                    <div class="form-check form-check-inline d-block m-1">
                                                        <input class="form-check-input" type="checkbox" id="column22" value="checked" :checked="column22" @click="showHideColum(22)">
                                                        <label class="form-check-label" for="column22">Calificación</label>
                                                    </div>
                                                 </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 col-md-4 mt-1">
                                <div class="d-flex justify-content-end align-items-center">
                                    <div class="dropdown" v-if="!hideMoneyBtn">
                                        <button type="button" class="btn btn-outline-primary dropdown-toggle hide-arrow waves-effect waves-float waves-light" @click="openPayOut()">
                                            <i class="las la-money-bill-wave-alt"></i> Liberar Dinero
                                        </button>
                                    </div>
                                    <div class="dropdown" v-if="!hideExportBtn">
                                        <button type="button" class="btn btn-outline-primary dropdown-toggle hide-arrow waves-effect waves-float waves-light" @click="exportOption()">
                                            <i class="las la-file-csv"></i> Exportar
                                        </button>
                                    </div>
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow py-0 waves-effect waves-float waves-light" @click="maximize()">
                                            <i class="las fs-30" :class="spandIcon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="table-responsive h-100">
                    <table class="table table-striped position-relative align-middle mb-5">
                        <thead class="fixed-head-table">
                            <tr>
                                <th colspan="1" class="text-center bg-my"></th>
                                <th v-if="title1 > 0" :colspan="title1" class="text-center bg-light-success">INFORMACIÓN</th>
                                <th v-if="title2 > 0" :colspan="title2" class="text-center bg-light-warning">PAY IN</th>
                                <th v-if="title3 > 0" :colspan="title3" class="text-center bg-light-primary">PAY OUT</th>
                                <th colspan="1" class="text-center bg-light-info"></th>
                            </tr>
                            <tr>
                                <th class="text-center bg-my text-success sticky align-middle"><div class="d-flex align-items-center justify-content-center">Profesional <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('sellerName')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('sellerName')"></i></span></div></th>
                                <th v-if="column1" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Consultante <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('clientName')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('clientName')"></i></span></div></th>
                                <th v-if="column2" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Título <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('title')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('title')"></i></span></div></th> 
                                <th v-if="column3" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Terapía <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('service')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('service')"></i></span></div></th>
                                <th v-if="column4" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Fecha Consulta <span><i class="las la-caret-up d-block arrow-short" @click="shortDateASC('date')"></i><i class="las la-caret-down d-block arrow-short" @click="shortDateDESC('date')"></i></span></div></th>
                                <th v-if="column5" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Hora Consulta </div></th>
                                <th v-if="column6" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Duración <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('duration')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('duration')"></i></span></div></th>
                                <th v-if="column20" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Modalidad <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('type')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('type')"></i></span></div></th>
                                <th v-if="column21" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Origén <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('createByProf')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('createByProf')"></i></span></div></th>
                                <th v-if="column23" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Recurrente <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('recurrent')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('recurrent')"></i></span></div></th>
                                <th v-if="column7" class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Estado <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('modality')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('modality')"></i></span></div></th>
                                <th v-if="column8" class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">Pendiente <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('PendingPay')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('PendingPay')"></i></span></div></th>
                                <th v-if="column9" class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">Cobrado </div></th>
                                <th v-if="column10" class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">Fecha Cobro </div></th>
                                <th v-if="column11" class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">ID Operación <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('sellId')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('sellId')"></i></span></div></th>
                                <th v-if="column12" class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">ID de Pago <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('payId')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('payId')"></i></span></div></th>
                                <th v-if="column13" class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">Pasarela <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('pasarela')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('pasarela')"></i></span></div></th>
                                <th v-if="column14" class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">% Comisión <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('comisionPorc')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('comisionPorc')"></i></span></div></th> 
                                <th v-if="column15" class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">Total Comisión <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('AmmountAs')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('AmmountAs')"></i></span></div></th>
                                <th v-if="column16" class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">Neto Profesional <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('netPrice')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('netPrice')"></i></span></div></th>
                                <th v-if="column17" class="text-center text-primary align-middle"><div class="d-flex align-items-center justify-content-center">Liberado <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('acctionLiberacion')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('acctionLiberacion')"></i></span></div></th>  
                                <th v-if="column18" class="text-center text-primary align-middle"><div class="d-flex align-items-center justify-content-center">Fecha de Depósito <span><i class="las la-caret-up d-block arrow-short" @click="shortDateASC('payout_date')"></i><i class="las la-caret-down d-block arrow-short" @click="shortDateDESC('payout_date')"></i></span></div></th>
                                <th v-if="column19" class="text-center text-primary align-middle"><div class="d-flex align-items-center justify-content-center">ID de Depósito <span><i class="las la-caret-up d-block arrow-short" @click="shortWordsDESC('cashout_id')"></i><i class="las la-caret-down d-block arrow-short" @click="shortWordsASC('cashout_id')"></i></span></div></th>
                                <th v-if="column22" class="text-center text-primary align-middle"><div class="d-flex align-items-center justify-content-center">Calificación <span><i class="las la-caret-up d-block arrow-short" @click="shortNumbersDESC('totalCalificate')"></i><i class="las la-caret-down d-block arrow-short" @click="shortNumbersASC('totalCalificate')"></i></span></div></th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="b in records" :key="b.id"> 
                                <td :data-search-term="b.sellerName" class="sticky">
                                    <div class="d-flex align-items-center" style="width: 250px;"> 
                                        <div class="w-25 text-center">
                                            <img v-if="b.imgSeller != '@/assets/imgs/user-img-small.png'" :src="b.imgSeller" class="" height="20" width="20" >
                                            <img v-else src="@/assets/imgs/user-img-small-gradient.png" class="" height="20" width="20">
                                        </div>
                                        <span class="fw-bold m-1">{{b.sellerName}}</span> 
                                    </div>   
                                </td>
                                <td v-if="column1">
                                    <div class="d-flex align-items-center" style="width: 250px;"> 
                                        <div class="w-25 text-center">
                                            <img v-if="b.imgClient != '@/assets/imgs/user-img-small.png'" :src="b.imgClient" class="" height="20" width="20" >
                                            <img v-else src="@/assets/imgs/user-img-small-gradient.png" class="" height="20" width="20">
                                        </div>
                                        <span class="fw-bold m-1">{{b.clientName}}</span> 
                                    </div>   
                                </td>
                                <td v-if="column2 && b.createByProf == 0"><div style="width: 120px;"> <a :href="'https://animasana.net/ar/es/service/'+b.linkId+'/'+b.link" target="blank" class="">{{b.title}}</a></div></td>
                                <td v-if="column2 && b.createByProf == 1"><div style="width: 120px;"> Cita Manual - {{b.title}}</div></td>
                                <td v-if="column3"><div style="width: 120px;"> {{b.service}}</div></td>
                                <td v-if="column4" class="text-center">{{b.date}}</td>
                                <td v-if="column5" class="text-center">{{b.hourStart}}</td>
                                <td v-if="column6" class="text-center">{{b.duration}}min.</td>
                                <td v-if="column20 && b.type == 1" class="text-center"><span class="badge rounded-pill badge-light-info">ONLINE</span></td>
                                <td v-if="column20 && b.type == 2" class="text-center"><span class="badge rounded-pill badge-light-warning">PRESENCIAL</span></td>
                                <td v-if="column21 && b.createByProf == 0 && b.fromHost == 0" class="text-center"><span class="badge rounded-pill badge-light-success">Animasana</span></td>
                                <td v-if="column21 && b.createByProf == 0 && b.fromHost == 1" class="text-center"><span class="badge rounded-pill badge-light-success">Landing</span></td>
                                <td v-if="column21 && b.createByProf == 1" class="text-center"><span class="badge rounded-pill badge-light-success">Manual</span></td>
                                <td v-if="column23 && b.recurrent == 1" class="text-center"><i class="las la-infinity"></i></td>
                                
                                <td v-if="column23 && b.recurrent == 0" class="text-center">--</td>
                                <td v-if="column7" class="text-center"><span class="badge rounded-pill" :class="b.modalityClass">{{b.modality}}</span></td>
                                <td v-if="column8 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center">{{b.currency}} {{b.PendingPay}}</td>
                                <td v-if="column8 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center"><span class="badge rounded-pill badge-light-info">{{b.price}}</span></td>
                                <td v-if="column9 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center">{{b.currency}} {{b.ammountPay}}</td>
                                <td v-if="column9 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center">--</td>
                                <td v-if="column10 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center"><div style="width: 120px;">{{b.datePay}}</div></td>
                                <td v-if="column10 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center"><div style="width: 120px;">--</div></td>
                                <td v-if="column11" class="text-center">{{b.sellId}}</td>
                                <td v-if="column12 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center"><div style="width: 120px;">{{b.payId}}</div></td>
                                <td v-if="column12 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center"><div style="width: 120px;">--</div></td>
                                <td v-if="column13 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center">{{b.pasarela}}</td>
                                <td v-if="column13 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center">--</td>
                                <td v-if="column14 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center">{{b.comisionPorc}}</td>
                                <td v-if="column14 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center">--</td>
                                <td v-if="column15 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center">{{b.currency}} {{b.AmmountAs}}</td>
                                <td v-if="column15 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center">--</td>
                                <td v-if="column16 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center">{{b.currency}} {{b.netPrice}}</td>
                                <td v-if="column16 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center">--</td>

                                <td v-if="column17 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center">
                                    <span class="badge rounded-pill" v-if="b.acctionLiberacion == 0" :class="b.classLiberacion">{{b.paid}}</span>
                                    <span class="badge rounded-pill" v-if="b.acctionLiberacion == 1" :class="b.classLiberacion">{{b.paid}}</span>
                                    <span class="badge rounded-pill" v-if="b.acctionLiberacion == 2" :class="b.classLiberacion">{{b.paid}}</span>  
                                </td>
                                <td v-if="column17 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center"><span class="badge rounded-pill" :class="b.classLiberacion">--</span></td>
                                <td v-if="column18 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center">{{b.payout_date}}</td>
                                <td v-if="column18 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center">--</td>
                                <td v-if="column19 && (b.price != 'Gratis' && b.price != 'Free')" class="text-center">{{b.cashout_id}}</td>
                                <td v-if="column19 && (b.price == 'Gratis' || b.price == 'Free')" class="text-center">--</td>
                                <td v-if="column22 && b.totalCalificate == '0'" class="text-center"><span class="badge rounded-pill badge-light-info">Sin Calificar</span></td>
                                <td v-if="column22 && b.totalCalificate != '0'" class="text-center">
                                    <div class="d-flex align-items-center w-100"> 
                                    <star-rating :increment="0.01" :star-size="15" :show-rating="true" :read-only="true" v-model:rating="b.totalCalificate" />
                                    </div>
                                </td>
                                <td>
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow py-0 waves-effect waves-float waves-light" data-bs-toggle="dropdown">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tableSearch">
                    <div class="input-group form-password-toggle">   
                        <input type="text" class="form-control" placeholder="Buscar dentro de los resultados por profesional, consultante, terapia, titulo, ids de pagos" v-model="textSearch" @keyup="findinArray()" >
                        <span class="input-group-text cursor-pointer"><i class="las la-search" @change="findinArray()"></i></span>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    <!-- popup liberar dinero -->

    <div class="container-overlay" v-if="showPayOut"></div>
    <div v-if="showPayOut" class="container-half-v">
        <div class="close" @click="closePayOut()"><i class="las la-times-circle"></i></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <payout :showRecordsDay="this.showRecordsDay" :dateSearch="this.dateSearch"></payout>
                </div>
            </div>
        </div>
        
    </div>
    
</template>
<script>
import axios from "axios";
import StarRating from 'vue-star-rating'
import payout from '@/components/admin-reports/payout.vue'
export default {  
    props: ['refUser', 'showUserName', 'userName', 'mode', 'publishRef', 'hideMoneyBtn', 'hideExportBtn'],
    name: "professionals-sales-form",
    components: {
        StarRating,
        payout
    },
    data(){
        return{
            records: [],
            recordsBkp: [],
            column1: true,
            column2: true,
            column3: true,
            column4: true,
            column5: true,
            column6: true,
            column7: true,
            column8: true,
            column9: true,
            column10: true,
            column11: true,
            column12: true,
            column13: true,
            column14: true,
            column15: true,
            column16: true,
            column17: true,
            column18: true,
            column19: true,
            column20: true,
            column21: true,
            column22: true,
            column23: true,
            title1: 10,
            title2: 9,
            title3: 4,
            fixedfullscreen: '',
            spandIcon: 'la-expand-arrows-alt',
            showRecordsDay: 7,
            tipeSearch: false,
            dateSearch: '',
            exportArray: [],
            export: false,
            ref: '',
            textSearch: '',
            refId: '',
            showPayOut: false,
            linkStart: '',
            adminType: 0
        }
    },
    mounted: function () {  
        var data = JSON.parse(localStorage.getItem('userData'));
        this.adminType = data.userType;
        this.linkStart = '/' + this.$route.params.browserCountry + '/' + this.$route.params.userLang + '/user-account/';
        if(this.refUser != ''){
            this.refId = this.refUser;
            this.showRecordsDay = 7;
        }
        this.findRecord();
    },
    methods:{
        openPayOut(){
            try{
                document.querySelector('body').style.overflow = 'hidden';
            }catch(e){

            }
            this.showPayOut = true;
        },
        closePayOut(){
            try{
                document.querySelector('body').style.overflow = 'auto';
            }catch(e){

            }
            this.showPayOut = false; 
        },
        searchDate(){
            var timestamp = new Date(this.dateSearch);
            timestamp = timestamp.getTime();
            if(isNaN(timestamp) == false){
                this.findRecord();
            }
            
        },
        changeSearchType(){
            if(this.tipeSearch == false){
                this.tipeSearch = true;
                this.dateSearch = '';
            }else{
                this.tipeSearch = false;
                this.dateSearch = '';
                this.findRecord();
            }
        },
        shortDateASC(val){
            if(val == 'date'){
                this.records.sort((a, b) => Number(new Date(b.dateIniNoFormat)) - Number(new Date(a.dateIniNoFormat)));
            }    
            if(val == 'datePay'){
                this.records.sort((a, b) => Number(new Date(b.datePayNoFormat)) - Number(new Date(a.datePayNoFormat)));
            }  
            if(val == 'payout_date'){
                this.records.sort((a, b) => Number(new Date(b.datePaidNoFormat)) - Number(new Date(a.datePaidNoFormat)));
            }    
        },
        shortDateDESC(val){
            if(val == 'date'){
                this.records.sort((a, b) => Number(new Date(a.dateIniNoFormat)) - Number(new Date(b.dateIniNoFormat)));
            }    
            if(val == 'datePay'){
                this.records.sort((a, b) => Number(new Date(a.datePayNoFormat)) - Number(new Date(b.datePayNoFormat)));
            }   
            if(val == 'payout_date'){
                this.records.sort((a, b) => Number(new Date(a.datePaidNoFormat)) - Number(new Date(b.datePaidNoFormat)));
            }    
        },
        shortNumbersASC(val){
            // sort by value
            if(val == 'duration'){
                this.records.sort((a, b) => a.duration - b.duration);
            }
            if(val == 'type'){
                this.records.sort((a, b) => a.type - b.type);
            }
            if(val == 'createByProf'){
                this.records.sort((a, b) => a.createByProf - b.createByProf);
            }
            if(val == 'recurrent'){
                this.records.sort((a, b) => a.recurrent - b.recurrent);
            }
            if(val == 'PendingPay'){
                this.records.sort((a, b) => parseFloat(a.PendingPay) - parseFloat(b.PendingPay));
            }
            if(val == 'comisionPorc'){
                this.records.sort((a, b) => a.comisionPorc - b.comisionPorc);
            }
            if(val == 'AmmountAs'){
                this.records.sort((a, b) => a.AmmountAs - b.AmmountAs);
            }
            if(val == 'netPrice'){
                this.records.sort((a, b) => parseFloat(a.netPrice) - parseFloat(b.netPrice));
            }
            if(val == 'acctionLiberacion'){
                this.records.sort((a, b) => a.acctionLiberacion - b.acctionLiberacion);
            }
            if(val == 'totalCalificate'){
                this.records.sort((a, b) => a.totalCalificate - b.totalCalificate);
            } 
        },
        shortNumbersDESC(val){
            // sort by value
            if(val == 'duration'){
                this.records.sort((a, b) => b.duration - a.duration);
            }
            if(val == 'type'){
                this.records.sort((a, b) => b.type - a.type);
            }
            if(val == 'createByProf'){
                this.records.sort((a, b) => b.createByProf - a.createByProf);
            }
            if(val == 'recurrent'){
                this.records.sort((a, b) => b.recurrent - a.recurrent);
            }
            if(val == 'PendingPay'){
                this.records.sort((a, b) => parseFloat(b.PendingPay) - parseFloat(a.PendingPay));
            }
            if(val == 'comisionPorc'){
                this.records.sort((a, b) => b.comisionPorc - a.comisionPorc);
            }
            if(val == 'AmmountAs'){
                this.records.sort((a, b) => b.AmmountAs - a.AmmountAs);
            }
            if(val == 'netPrice'){
                this.records.sort((a, b) => parseFloat(b.netPrice) - parseFloat(a.netPrice));
            }
            if(val == 'acctionLiberacion'){
                this.records.sort((a, b) => b.acctionLiberacion - a.acctionLiberacion);
            }
            if(val == 'totalCalificate'){
                this.records.sort((a, b) => b.totalCalificate - a.totalCalificate);
            }
        },
        shortWordsASC(val){
            // sort by name
            this.records.sort((a, b) => {
                let nameA;
                let nameB;
            if(val == 'sellerName'){
                nameA = a.sellerName.toUpperCase(); // ignore upper and lowercase
                nameB = b.sellerName.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'clientName'){
                nameA = a.clientName.toUpperCase(); // ignore upper and lowercase
                nameB = b.clientName.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'title'){
                nameA = a.title.toUpperCase(); // ignore upper and lowercase
                nameB = b.title.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'service'){
                nameA = a.service.toUpperCase(); // ignore upper and lowercase
                nameB = b.service.toUpperCase(); // ignore upper and lowercase
            }  
            if(val == 'modality'){
                nameA = a.modality.toUpperCase(); // ignore upper and lowercase
                nameB = b.modality.toUpperCase(); // ignore upper and lowercase
            }  
            if(val == 'sellId'){
                nameA = a.sellId.toUpperCase(); // ignore upper and lowercase
                nameB = b.sellId.toUpperCase(); // ignore upper and lowercase
            } 
            if(val == 'payId'){
                nameA = a.payId.toUpperCase(); // ignore upper and lowercase
                nameB = b.payId.toUpperCase(); // ignore upper and lowercase
            } 
            if(val == 'pasarela'){
                nameA = a.pasarela.toUpperCase(); // ignore upper and lowercase
                nameB = b.pasarela.toUpperCase(); // ignore upper and lowercase
            } 
            if(val == 'cashout_id'){
                nameA = a.cashout_id.toUpperCase(); // ignore upper and lowercase
                nameB = b.cashout_id.toUpperCase(); // ignore upper and lowercase
            } 

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
            });

        },
        shortWordsDESC(val){
            // sort by name
            this.records.sort((a, b) => {
                let nameA;
                let nameB;
            if(val == 'sellerName'){
                nameA = a.sellerName.toUpperCase(); // ignore upper and lowercase
                nameB = b.sellerName.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'clientName'){
                nameA = a.clientName.toUpperCase(); // ignore upper and lowercase
                nameB = b.clientName.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'title'){
                nameA = a.title.toUpperCase(); // ignore upper and lowercase
                nameB = b.title.toUpperCase(); // ignore upper and lowercase
            }
            if(val == 'service'){
                nameA = a.service.toUpperCase(); // ignore upper and lowercase
                nameB = b.service.toUpperCase(); // ignore upper and lowercase
            }  
            if(val == 'modality'){
                nameA = a.modality.toUpperCase(); // ignore upper and lowercase
                nameB = b.modality.toUpperCase(); // ignore upper and lowercase
            }  
            if(val == 'sellId'){
                nameA = a.sellId.toUpperCase(); // ignore upper and lowercase
                nameB = b.sellId.toUpperCase(); // ignore upper and lowercase
            }  
            if(val == 'payId'){
                nameA = a.payId.toUpperCase(); // ignore upper and lowercase
                nameB = b.payId.toUpperCase(); // ignore upper and lowercase
            }  
            if(val == 'pasarela'){
                nameA = a.pasarela.toUpperCase(); // ignore upper and lowercase
                nameB = b.pasarela.toUpperCase(); // ignore upper and lowercase
            } 
            if(val == 'cashout_id'){
                nameA = a.cashout_id.toUpperCase(); // ignore upper and lowercase
                nameB = b.cashout_id.toUpperCase(); // ignore upper and lowercase
            }  
            if (nameA > nameB) {
                return -1;
            }
            if (nameA < nameB) {
                return 1;
            }

            // names must be equal
            return 0;
            });
        },
        maximize(){
            if(this.fixedfullscreen == ""){
                this.fixedfullscreen = "fixed-full-screen";
                this.spandIcon = "la-compress-arrows-alt";
            }else{
                this.fixedfullscreen = "";
                this.spandIcon = "la-expand-arrows-alt";
            }
        },
        showHideColum(val){
            if(val == 1){
                if(this.column1 == true){
                    this.column1 = false;
                    this.title1--;
                }else{
                    this.column1 = true;
                    this.title1++;
                }
            }else if(val == 2){
                if(this.column2 == true){
                    this.column2 = false;
                    this.title1--;
                }else{
                    this.column2 = true;
                    this.title1++;
                }
            }else if(val == 3){
                if(this.column3 == true){
                    this.column3 = false;
                    this.title1--;
                }else{
                    this.column3 = true;
                    this.title1++;
                }
            }else if(val == 4){
                if(this.column4 == true){
                    this.column4 = false;
                    this.title1--;
                }else{
                    this.column4 = true;
                    this.title1++;
                }
            }else if(val == 5){
                if(this.column5 == true){
                    this.column5 = false;
                    this.title1--;
                }else{
                    this.column5 = true;
                    this.title1++;
                }
            }else if(val == 6){
                if(this.column6 == true){
                    this.column6 = false;
                    this.title1--;
                }else{
                    this.column6 = true;
                    this.title1++;
                }
            }else if(val == 7){
                if(this.column7 == true){
                    this.column7 = false;
                    this.title1--;
                }else{
                    this.column7 = true;
                    this.title1++;
                }
            }else if(val == 20){
                if(this.column20 == true){
                    this.column20 = false;
                    this.title1--;
                }else{
                    this.column20 = true;
                    this.title1++;
                }

            }else if(val == 21){
                if(this.column21 == true){
                    this.column21 = false;
                    this.title1--;
                }else{
                    this.column21 = true;
                    this.title1++;
                }

            }else if(val == 23){
                if(this.column23 == true){
                    this.column23 = false;
                    this.title1--;
                }else{
                    this.column23 = true;
                    this.title1++;
                }
            }else if(val == 8){
                if(this.column8 == true){
                    this.column8 = false;
                    this.title2--;
                }else{
                    this.column8 = true;
                    this.title2++;
                }
            }else if(val == 9){
                if(this.column9 == true){
                    this.column9 = false;
                     this.title2--;
                }else{
                    this.column9 = true;
                    this.title2++;
                }
            }else if(val == 10){
                if(this.column10 == true){
                    this.column10 = false;
                    this.title2--;
                }else{
                    this.column10 = true;
                    this.title2++;
                }
            }else if(val == 11){
                if(this.column11 == true){
                    this.column11 = false;
                    this.title2--;
                }else{
                    this.column11 = true;
                    this.title2++;
                }
            }else if(val == 12){
                if(this.column12 == true){
                    this.column12 = false;
                    this.title2--;
                }else{
                    this.column12 = true;
                    this.title2++;
                }
            }else if(val == 13){
                if(this.column13 == true){
                    this.column13 = false;
                    this.title2--;
                }else{
                    this.column13 = true;
                    this.title2++;
                }
            }else if(val == 14){
                if(this.column14 == true){
                    this.column14 = false;
                    this.title2--;
                }else{
                    this.column14 = true;
                    this.title2++;
                }
            }else if(val == 15){
                if(this.column15 == true){
                    this.column15 = false;
                    this.title2--;
                }else{
                    this.column15 = true;
                    this.title2++;
                }
            }else if(val == 16){
                if(this.column16 == true){
                    this.column16 = false;
                    this.title2--;
                }else{
                    this.column16 = true;
                    this.title2++;
                }
            }else if(val == 17){
                if(this.column17 == true){
                    this.column17 = false;
                     this.title3--;
                }else{
                    this.column17 = true;
                    this.title3++;
                } 
            }else if(val == 18){
                if(this.column18 == true){
                    this.column18 = false;
                     this.title3--;
                }else{
                    this.column18 = true;
                    this.title3++;
                }
            }else if(val == 19){
                if(this.column19 == true){
                    this.column19 = false;
                     this.title3--;
                }else{
                    this.column19 = true;
                    this.title3++;
                }
            }else if(val == 22){
                if(this.column22 == true){
                    this.column22 = false;
                     this.title3--;
                }else{
                    this.column22 = true;
                    this.title3++;
                }
            }
        },
        exportOption(){
            this.export = true;
            this.findRecord();
        },
        download(dataurl, filename) {
            const link = document.createElement("a");
            link.href = dataurl;
            link.download = filename;
            link.click();
        },
        async findRecord(){ 
            if(this.export == true){
                this.exportArray = this.records;
            }else{
                this.exportArray = '';
                this.records = [];
            }
            var payload = {
                action: 'admin-get-professionals-sales-reports',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                show: this.showRecordsDay,
                dateSearch: this.dateSearch,
                exportArray: this.exportArray,
                ref: this.refId,
                mode: this.mode,
                publishRef: this.publishRef
            }
            await axios.post('/index.php', payload).then(result => {
                if(this.export == true){
                    if(window.location.host == 'back.animasana.net'){
                        this.download('https://api.animasana.net' + result.data.file, 'nombre.xlsx');  
                    }else if(window.location.host == 'api-qa.animasana.net'){
                        this.download('https://api-qa.animasana.net' + result.data.file, 'nombre.xlsx');  
                    }else{
                       this.download('https://api-qa.animasana.net' + result.data.file, 'nombre.xlsx'); 
                    }
                }else{
                    this.records = result.data; 
                    this.recordsBkp = result.data; 
                }
                this.export = false;
            })
            .catch(error => {
                return;
            })  
        },
        findinArray(){
            var matches = this.recordsBkp;
            this.records = matches.filter(
                s => s.sellerName.toLowerCase().includes(this.textSearch.toLowerCase()) 
                || s.clientName.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.title.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.service.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.sellId.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.payId.toLowerCase().includes(this.textSearch.toLowerCase())
                || s.cashout_id.toLowerCase().includes(this.textSearch.toLowerCase())
            );
        },
        expandInfo(val, userId, userName, mode, publishRef){
            this.$root.expandInfo(val, userId, userName, mode, publishRef);
        }
     }
}
</script>
<style scoped>
.tableSearch{
    position: sticky;
    z-index: 9;
    bottom: 15px;
    width: 40%;
    background: #6e6b7bbd;
    padding: 10px;
    margin: auto;
    border-radius: 5px;
}
.fixed-full-screen .tableSearch{
position: fixed;
    bottom: 48px;
    left: 0;
    right: 0;
    }
@media (max-width: 600px) {
    .tableSearch{
    width: 95%;
    }
}
@media (min-width: 600px) {
th.bg-my{
    position:sticky;
  left:0px;
  z-index: 2;
}
td.sticky
{
  position:sticky;
  left:0px;
  z-index: 2;
  background: #fff;
}
.dark-layout td.sticky
{
  position:sticky;
  left:0px;
  z-index: 2;
  background: #283046;
}
tr.sticky:not(caption) > *{
    box-shadow: inset 0 0 0 9999px #545454;
}

.dark-layout tr.sticky:not(caption) > *{
    box-shadow: inset 0 0 0 9999px #545454;
}
}
.fixed-full-screen{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 5px;
    width: 100%;
    height: 100%;
    background: #f7f7f7;
    overflow: auto;
    right: 0;
    padding: 10px;
}
.dark-layout .fixed-full-screen{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 5px;
    width: 100%;
    height: 100%;
    background: #161d31;
    overflow: auto;
    right: 0;
    padding: 10px;
}
.pointer{
    cursor: pointer;
}
.fixed-head-table{
    position: sticky;
    top: 0;
    background: #343d55;
    z-index: 4;
}
.dark-layout .fixed-head-table{
    position: sticky;
    top: 0;
    background: #343d55;
    z-index: 4;
}
.arrow-short{
    margin-left: 10px;
}
.fs-30{
    font-size: 30px;
}
.container-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 41, 47, 0.5);
    cursor: pointer;
    transition: all 0.7s, z-index 0s;
    z-index: 16;
}

.dark-layout .container-half-v{
    background: #171e31;
}

.container-half-v{
    width: 50%;
    height: 100%;
    overflow: auto;
    position: fixed;
    z-index: 84;
    top: 0;
    right: 0;
    bottom: 0;
    background: #171e31;
}
@media (max-width: 600px) {
    .container-half-v{
        width: 100%;
    }
}
.container-half-v .close {
    font-size: 30px;
    position: fixed;
    z-index: 2;
    right: 22px;
    top: 11px;
    color: #7367f0;
    cursor: pointer;
}
</style>