<template>
  <div :class="darkMode">
    <div class="pace-done vertical-layout vertical-menu-modern navbar-floating footer-static" :class="expandMenuClass">
      <router-view/>
      <expandInfo v-if="openClose" ref="help" :openHelp="this.openClose" @openHelp="openHelp" :refId="this.userId" :type="this.type" :userName="this.userName" :mode="this.mode" :publishRef="this.publishRef"></expandInfo>
    </div>
  </div>
</template>
<script>
import expandInfo from '@/components/admin-navs/expandInfo.vue'
export default{
  components: {
      expandInfo,
  },
  data(){
        return{
          openClose: false,
          userId: '',
          type: '',
          userName: '',
          mode: '',
          publishRef: '',
          expandMenuClass: '',
          darkMode: ''
        }
  },
  mounted: function () {
    console.log(this.$route.name)
    if(localStorage.getItem('darkMode')){
      this.darkMode = localStorage.getItem('darkMode');
      document.querySelector('body').classList.add(this.darkMode); 
    }
    if(localStorage.getItem('token')){
            if(localStorage.getItem('token') != ''){
               
            }else{
              this.$router.push({ name: 'startLocation'});     
            }
    }else{
        this.$router.push({ name: 'startLocation'});
    }  
  },
  methods: {
    changeDarkMode(val){       
      this.darkMode = val;   
      if(this.darkMode != ''){
        document.querySelector('body').classList.add(this.darkMode);       
      }else{
        document.querySelector('body').classList.remove('dark-layout');       
      }
          
    },
    expandInfo(val, userId, userName, mode, publishRef){
      this.type = '';
      this.openClose = false;
      this.userName = '';
      var a = this;  
      setTimeout(function(){   
        a.type = val;
        a.openClose = true;
        a.userName = userName;
        a.mode = mode
        a.publishRef = publishRef;
      }, 200);
      
      this.type = a.type;
      this.userId = userId;  
      this.userName = a.userName;
      this.mode = a.mode;
      this.openClose = a.openClose;
      this.publishRef = a.publishRef;
    },
    closeModal(){
      this.openClose = false;
    },
    expandMenu(){
      if(this.expandMenuClass == ""){
        this.expandMenuClass = 'menu-expanded';
      }else{
        this.expandMenuClass = '';
      }
    }
  }
}
</script>
<style lang="css" src="./assets/styles/vendors.min.css" />
<style lang="css" src="./assets/styles/charts/apexcharts.css" />
<style lang="css" src="./assets/styles/extensions/toastr.min.css" />
<style lang="css" src="./assets/styles/bootstrap.css" />
<style lang="css" src="./assets/styles/bootstrap-extended.css" />
<style lang="css" src="./assets/styles/colors.css" />
<style lang="css" src="./assets/styles/components.css" />


<style lang="css" src="./assets/styles/themes/dark-layout.css" />
<style lang="css" src="./assets/styles/themes/bordered-layout.css" />
<style lang="css" src="./assets/styles/themes/semi-dark-layout.css" />

<style lang="css" src="./assets/styles/core/menu/menu-types/vertical-menu.css" />
<style lang="css" src="./assets/styles/pages/app-todo.css" />
<style lang="css" src="./assets/styles/pages/app-ecommerce.css" />


<style lang="css" src="./assets/styles/animate.css" />
<style lang="css" src="@/assets/styles/slim.min.css" />


        
