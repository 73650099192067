<template>
    <div class="waiting-payout" v-if="showMsgWiting">
        <div class="d-flex justify-content-center align-items-center">
            <div class="text-center">
                <img src="@/assets/imgs/spinner-white.webp" class="img-fluid w-25">
                <h3 v-html="msgWiting" class="p-2"></h3>
            </div>
        </div>
    </div>
    <div class="row" id="basic-table">
        <div class="col-12">    
            <div class="card">
                <div class="card-header d-md-block">
                    <h4 class="card-title">Mostrando los últimos {{showRecordsDay}} días de operaciones listas para liberar.</h4>
                </div>
                <div class="container sticky-top" style="background-color: #283046;">
                    <div class="row">
                        <div class="col-lg-12">    
                            <div class="w-100 d-flex justify-content-center align-items-center mb-1">
                                <h5 class="m-1">PASARELA:</h5>
                                <div class="form-check m-1">
                                    <input class="form-check-input" type="radio" name="type" id="type1" value="1" v-model="pasarell" @click="this.pasarell = 1; findRecord()">
                                    <label class="form-check-label" for="type1" @click="this.pasarell = 1; findRecord()">
                                    DLocal
                                    </label>
                                </div>
                                <div class="form-check m-1">
                                    <input class="form-check-input" type="radio" name="type" id="type2" value="2" v-model="pasarell" @click="this.pasarell = 2; findRecord()">
                                    <label class="form-check-label" for="type2" @click="this.pasarell = 2; findRecord()">
                                    Stripe
                                    </label>
                                </div>
                                <div class="form-check m-1 d-none">
                                    <input class="form-check-input" type="radio" name="type" id="type3" value="5" v-model="pasarell" @click="this.pasarell = 3; findRecord()">
                                    <label class="form-check-label" for="type3" @click="this.pasarell = 3; findRecord()">
                                    Chronos
                                    </label>
                                </div>
                            </div>
                            <hr>
                            <div class="w-100 d-flex justify-content-center align-items-center mb-1">
                                <button class="btn btn-outline-primary btn-sm me-1 waves-effect waves-float waves-light" type="button" v-if="!sellectAll" @click="SelectAll()">
                                    Marcar todos
                                </button>
                                <button class="btn btn-outline-primary btn-sm me-1 waves-effect waves-float waves-light" type="button" v-if="sellectAll" @click="SelectAll()">
                                    Desmarcar todos
                                </button>
                            </div>
                            <h5 class="w-100 text-center" v-if="Object.keys(this.totalsPayOut).length > 0">MONTOS TOTALES A LIBERAR</h5>
                            <div class="w-100 d-flex justify-content-center align-items-center mb-1">
                                <h3 v-for="(b, index) in Object.keys(this.totalsPayOut)" :key="index" class="p-1 m-0"><b>{{ b }}{{ this.totalsPayOut[b] }}</b></h3>
                            </div>
                            <div class="text-center mb-2">
                                <button v-if="Object.keys(this.totalsPayOut).length > 0 && this.pasarell == 1" class="btn btn-primary me-1 waves-effect waves-float waves-light" type="button" @click="payOutDlocal()">
                                    Liberar Dinero
                                </button>
                                <button v-if="Object.keys(this.totalsPayOut).length > 0 && this.pasarell == 2" class="btn btn-primary me-1 waves-effect waves-float waves-light" type="button" @click="payOutStripe()">
                                    Marcar Como Liberados
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(b, indexA) in records" :key="indexA">
                    
                    <section v-if="b.PayOutStatusCode != '0' && b.sellerActive != '0'">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card border">
                                    <div class="card-header">    
                                        <div class="w-50">
                                            <div class="d-flex align-items-center" style="width: 250px;"> 
                                                <div class="form-check form-check-inline" v-if="b.pasarell == 1 && b.bank_name != ''">
                                                    <input class="form-check-input" type="checkbox" :id="'client'+indexA" :checked="b.select" @click="selectSingle(indexA)"> 
                                                </div>
                                                <div class="form-check form-check-inline" v-if="b.pasarell == 1 && b.bank_name == ''">
                                                    <input class="form-check-input" type="checkbox" :id="'client'+indexA" :checked="b.select" disabled> 
                                                </div>
                                                <div class="form-check form-check-inline" v-if="b.pasarell == 2 && b.emailWallet != ''">
                                                    <input class="form-check-input" type="checkbox" :id="'client'+indexA" :checked="b.select" @click="selectSingle(indexA)"> 
                                                </div>
                                                <div class="form-check form-check-inline" v-if="b.pasarell == 2 && b.emailWallet == ''">
                                                    <input class="form-check-input" type="checkbox" :id="'client'+indexA" :checked="b.select" disabled> 
                                                </div>
                                                <div class="w-25 text-center">
                                                    <img v-if="b.imgSeller != '@/assets/imgs/user-img-small.png'" :src="b.imgSeller" class="img-fluid avatar avatar-xl">
                                                    <img v-else src="@/assets/imgs/user-img-small-gradient.png" class="img-fluid avatar avatar-xl">
                                                </div>
                                                <span class="fw-bold m-1">{{b.professional}}</span> 
                                            </div>   
                                        </div>
                                        <div class="w-50">
                                            <h5 v-if="b.pasarell == 1" class="card-title">Banco: {{b.bank_name}}</h5>
                                            <h5 v-if="b.pasarell == 2" class="card-title">Email <span v-if="b.walletType == '1'">Payoneer</span><span v-if="b.walletType == '2'">Takenos </span> {{b.emailWallet}} <a v-if="b.emailWallet != ''" tabindex="0" title="Copiar Mail" @click="copyText(b.userId)"><i class="lar la-clipboard"></i></a></h5>
                                            <h4 class="card-title">Total: {{b.currency}} {{b.amount}}</h4>
                                        </div> 
                                    </div>
                                    <div class="card-body">
                                        <p class="small text-center text-danger" v-if="b.bank_name == '' && b.pasarell == 1">No se puede realizar el PayOut hasta que no agregue una cuenta bancaria.</p>
                                        <p class="small text-center text-danger" v-if="b.emailWallet == '' && b.pasarell == 2">No se puede realizar el PayOut hasta que no agregue una cuenta de Payoneer o Takenos.</p>
                                        <p class="small text-center text-danger" v-if="b.PayOutStatusCode != '0' && b.PayOutStatusCode != ''">{{b.PayOutStatusSms}}</p>
                                        
                                        <p class=" text-end">
                                            <button class="btn btn-outline-primary btn-sm me-1 waves-effect waves-float waves-light" v-if="adminType != '3'" @click="assist(b.linkAssist)"><i class="las la-headset"></i></button> 
                                            <a class="btn btn-outline-primary btn-sm me-1 waves-effect waves-float waves-light" :href="'https://api.whatsapp.com/send/?phone='+b.userPhone" target="blank"><i class="lab la-whatsapp"></i></a>
                                            <a class="btn btn-outline-primary btn-sm me-1 waves-effect waves-float waves-light" :href="'mailto:'+b.userMail" target="blank"><i class="las la-envelope"></i></a>
                                            <button class="btn btn-outline-primary btn-sm me-1 waves-effect waves-float waves-light" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseA'+indexA" aria-expanded="true" :aria-controls="'collapseA'+indexA">
                                                Operaciones
                                            </button>
                                            <button v-if="b.pasarell == 1" class="btn btn-outline-primary btn-sm me-1 waves-effect waves-float waves-light" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseB'+indexA" aria-expanded="false" :aria-controls="'collapseB'+indexA">
                                                Datos Bancarios
                                            </button>
                                        </p>
                                        <input type="text" class="d-none" :id="'thide'+b.userId" :value="b.emailWallet">
                                        <div v-if="b.pasarell == 1" class="collapse" :id="'collapseB'+indexA" style="">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <p v-if="b.bank_name != ''">Banco: <b>{{ b.bank_name }}</b></p>
                                                        <p v-else>Banco: <b class="text-danger">No Ingresado</b></p>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <p v-if="b.bank_name != ''">Titular: <b>{{ b.beneficiary_name +' '+ b.beneficiary_lastname}}</b></p>
                                                        <p v-else>Titular: <b class="text-danger">No Ingresado</b></p>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <p v-if="b.bank_name != ''">Cuenta Nº: <b>{{ b.bank_account }}</b></p>
                                                        <p v-else>Cuenta Nº: <b class="text-danger">No Ingresado</b></p>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <p v-if="b.bank_name != ''">Moneda: <b>{{ b.currency }}</b></p>
                                                        <p v-else>Moneda: <b class="text-danger">No Ingresado</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="collapse" :id="'collapseA'+indexA" style="">
                                            <div class="d-flex border overflow-auto">
                                                <table class="table table-striped position-relative align-middle">
                                                    <thead class="fixed-head-table">
                                                        <tr> 
                                                            <th class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Título</div></th> 
                                                            <th class="text-center text-success align-middle"><div class="d-flex align-items-center justify-content-center">Fecha Consulta</div></th>
                                                            <th class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">Fecha Cobro </div></th>
                                                            <th class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">ID Operación</div></th>
                                                            <th class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">ID de Pago</div></th>
                                                            <th class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">% Comisión</div></th> 
                                                            <th class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">Total Comisión</div></th>
                                                            <th class="text-center text-warning align-middle"><div class="d-flex align-items-center justify-content-center">Neto Profesional</div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(c, indexB) in b.bookings" :key="indexB">   
                                                            <!--<td class="sticky">
                                                                <div class="d-flex align-items-center" style="width: 250px;"> 
                                                                    <div class="w-25 text-center">
                                                                        <img v-if="c.imgSeller != '@/assets/imgs/user-img-small.png'" :src="c.imgSeller" class="" height="20" width="20" >
                                                                        <img v-else src="@/assets/imgs/user-img-small-gradient.png" class="" height="20" width="20">
                                                                    </div>
                                                                    <span class="fw-bold m-1">{{c.sellerName}}</span> 
                                                                </div>   
                                                            </td>-->
                                                            <td v-if="c.createByProf == 0"><div style="width: 120px;"> <a :href="'https://animasana.net/ar/es/service/'+c.linkId+'/'+c.link" target="blank" class="text-white">{{c.title}}</a></div></td>
                                                            <td v-if="c.createByProf == 1"><div style="width: 120px;"> Cita Manual - {{c.title}}</div></td>
                                                            <td class="text-center">{{c.date}}</td>
                                                            <td v-if="c.price != 'Gratis' && c.price != 'Free'" class="text-center"><div style="width: 120px;">{{c.datePay}}</div></td>
                                                            <td class="text-center">{{c.sellId}}</td>
                                                            <td v-if="c.price != 'Gratis' && c.price != 'Free'" class="text-center"><div style="width: 120px;">{{c.payId}}</div></td>
                                                            <td v-if="c.price != 'Gratis' && c.price != 'Free'" class="text-center">{{c.comisionPorc}}</td>
                                                            <td v-if="c.price != 'Gratis' && c.price != 'Free'" class="text-center">{{c.currency}} {{c.AmmountAs}}</td>
                                                            <td v-if="c.price != 'Gratis' && c.price != 'Free'" class="text-center">{{c.currency}} {{c.netPrice}}</td> 
                                                        </tr>
                                                    </tbody> 
                                                </table>       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>     
            </div>
        </div>  
    </div> 
    <div class="position-fixed start-0 p-3" style="z-index: 11000; bottom: 60px">
        <div class="toast align-items-center text-white bg-success border-0" ref="el2">
            <div class="d-flex">
                <div class="toast-body">
                    {{errorText}}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'bootstrap';
import axios from "axios";
export default {  
    props: ['showRecordsDay', 'dateSearch'],
    name: "payout",
    components: {
     
    },
    data(){
        return{
            records: [],
            recordsBkp: [],
            mode: '1',
            publishRef: '',
            refId: '',
            pasarell: 1,
            sellectAll: false,
            totalsPayOut: [],
            msgWiting: '',
            showMsgWiting: true,
            errorText: '',
            adminType: 0
        }
    },
    mounted: function () {  
        var data = JSON.parse(localStorage.getItem('userData'));
        this.adminType = data.userType;
        this.findRecord();
    },
    methods:{
        
        copyText(val) {
            // Get the text field
            var copyText = document.getElementById("thide"+val);

            // Select the text field
            copyText.select();
            copyText.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText.value);
            this.errorText = 'Email copiado.';
            var toast = new Toast(this.$refs.el2)
            toast.show()  
            // Alert the copied text
            
        },
        assist(val){
            const link = document.createElement("a");
            if(window.location.host == 'back.animasana.net'){
                link.href = 'https://animasana.net/' + this.$route.params.browserCountry + '/' + this.$route.params.userLang + '/autologin/' + val;  
            }else if(window.location.host == 'api-qa.animasana.net'){
                link.href = 'http://localhost:8080/' + this.$route.params.browserCountry + '/' + this.$route.params.userLang + '/autologin/' + val;  
            }else{
                link.href = 'http://localhost:8081/' + this.$route.params.browserCountry + '/' + this.$route.params.userLang + '/autologin/' + val;  
            }
            
            link.target = 'blank';
            link.click();
        },
        async findRecord(){ 
            this.msgWiting = 'BUSCANDO OPERACIONES...';
            this.showMsgWiting = true;
            this.records = []; 
            var payload = {
                action: 'admin-get-professionals-sales-payout',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                show: this.showRecordsDay,
                dateSearch: this.dateSearch,
                exportArray: false,
                ref: this.refId,
                mode: this.mode,
                publishRef: this.publishRef,
                pasarell: this.pasarell
            }
            await axios.post('/index.php', payload).then(result => {
            console.log(result)
                this.msgWiting = '';
                this.showMsgWiting = false;
                this.records = result.data; 
                this.calculateTotalsPayOut();    
            })
            .catch(error => {
                return;
            })  
        },
        async payOutStripe(){
            var answer = confirm('¿Confirmas que deseas marcar como liberados los pagos seleccionados?');
            if(answer){
                this.msgWiting = 'MARCANDO PAGOS Y AVISANDO A LOS PROFESIONLES.<br><br>Esta operanción puede demorar unos minutos dependiendo de la cantidad de pagos a liberar.';
                this.showMsgWiting = true;
                var payload = {
                    action: 'admin-payout-stripe',
                    browserCountry: this.$route.params.browserCountry,
                    userLang: this.$route.params.userLang,
                    urlcode: window.location.host,
                    pays: JSON.stringify(this.records)
                }
                await axios.post('/index.php', payload).then(result => {
                    
                    this.msgWiting = '';
                    this.showMsgWiting = false; 
                    this.records = result.data; 


                    try{
                        Object.keys(this.records).forEach(key => {  
                            this.records[key].select = false;           
                        });  
                        this.sellectAll = false;
                    }catch(e){
                    } 
                    this.calculateTotalsPayOut();      
                    this.$parent.findRecord();  
                })
                .catch(error => {
                    return;
                })  
            }
        },
        async payOutDlocal(){
            var answer = confirm('¿Confirmas que deseas liberar los pagos seleccionados?');
            if(answer){
                this.msgWiting = 'LIBERANDO PAGOS Y AVISANDO A LOS PROFESIONLES.<br><br>Esta operanción puede demorar unos minutos dependiendo de la cantidad de pagos a liberar.';
                this.showMsgWiting = true;
                var payload = {
                    action: 'admin-payout-dlocal',
                    browserCountry: this.$route.params.browserCountry,
                    userLang: this.$route.params.userLang,
                    urlcode: window.location.host,
                    pays: JSON.stringify(this.records)
                }
                await axios.post('/index.php', payload).then(result => {
                   
                    this.msgWiting = '';
                    this.showMsgWiting = false; 
                    this.records = result.data; 
                    try{
                        Object.keys(this.records).forEach(key => {  
                            this.records[key].select = false;           
                        });  
                        this.sellectAll = false;
                    }catch(e){
                    } 
                    this.calculateTotalsPayOut();      
                    this.$parent.findRecord();  
                })
                .catch(error => {
                    return;
                })  
            }
        },
        SelectAll(){
            try{
                if(this.sellectAll == false){
                    Object.keys(this.records).forEach(key => {
                        if(this.records[key].pasarell == '1' && this.records[key].bank_name != ''){
                            this.records[key].select = true;
                        }else if(this.records[key].pasarell == '2' && this.records[key].emailWallet != ''){
                            this.records[key].select = true;
                        }   
                    });   
                }else{
                    Object.keys(this.records).forEach(key => {  
                        this.records[key].select = false;           
                    });  
                    this.sellectAll = false;
                }
                this.calculateTotalsPayOut();
            }catch(e){

            } 
        },
        selectSingle(val){
            if(this.records[val].select == false){
                this.records[val].select = true;
            }else{
                this.records[val].select = false;
            }
            this.calculateTotalsPayOut();
        },
        calculateTotalsPayOut(){
            this.totalsPayOut = [];
            Object.keys(this.records).forEach(key => {  
                if( this.records[key].select == true){
                    if(this.totalsPayOut.hasOwnProperty(this.records[key].currency)){
                        this.totalsPayOut[this.records[key].currency] = (parseFloat(this.totalsPayOut[this.records[key].currency]) + parseFloat(this.records[key].amount));
                    }else{        
                       this.totalsPayOut[this.records[key].currency] = parseFloat(this.records[key].amount);
                    }
                }         
            });  
        },
        expandInfo(val, userId, userName, mode, publishRef){
            this.$root.expandInfo(val, userId, userName, mode, publishRef);
        }
     }
}
</script>
<style scoped>
.waiting-payout{
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #283046;
    top: 0;
    left: 0;
}
</style>