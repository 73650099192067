<template>
  
</template>

<script>
import axios from "axios";

export default { 
  name: "startLocation",
  
  data(){
      return{
          
      }
  },
  mounted: function () { 
    if(this.$route.name == 'startLocation'){
      if(localStorage.getItem('routeCountry')){
        if(localStorage.getItem('routeCountry') == 'AR'){
          this.$router.push({path: '/ar/es/', params: { browserCountry:'ar', userLang: 'es' } , name: 'homeLang'});
        }else if(localStorage.getItem('routeCountry') == 'CL'){
          this.$router.push({path: '/cl/es/', params: { browserCountry:'cl', userLang: 'es' } , name: 'homeLang'});
        }else if(localStorage.getItem('routeCountry') == 'mx'){
          this.$router.push({path: '/mx/es/', params: { browserCountry:'mx', userLang: 'es' } , name: 'homeLang'});
        }else if(localStorage.getItem('routeCountry') == 'us'){
          this.$router.push({path: '/us/es/', params: { browserCountry:'us', userLang: 'es' } , name: 'homeLang'});
        }else{
          this.$router.push({path: '/la/es/', params: { browserCountry:'la', userLang: 'es' } , name: 'homeLang'});
        }
      }else{
        axios.get('https://ipapi.co/country/').then(response => {
          localStorage.setItem('routeCountry', response.data);
          if(localStorage.getItem('routeCountry') == 'AR'){
            this.$router.push({path: '/ar/es/', params: { browserCountry:'ar', userLang: 'es' } , name: 'homeLang'});
          }else if(localStorage.getItem('routeCountry') == 'CL'){
            this.$router.push({path: '/cl/es/', params: { browserCountry:'cl', userLang: 'es' } , name: 'homeLang'});
          }else if(localStorage.getItem('routeCountry') == 'mx'){
            this.$router.push({path: '/mx/es/', params: { browserCountry:'mx', userLang: 'es' } , name: 'homeLang'});
          }else if(localStorage.getItem('routeCountry') == 'us'){
            this.$router.push({path: '/us/es/', params: { browserCountry:'us', userLang: 'es' } , name: 'homeLang'});
          }else{
            this.$router.push({path: '/la/es/', params: { browserCountry:'la', userLang: 'es' } , name: 'homeLang'});
          }  
        })
        .catch(error => {
            localStorage.setItem('routeCountry', 'AR');
            this.$router.push({path: '/la/es/', params: { browserCountry:'la', userLang: 'es' } , name: 'homeLang'});
            return;
        })
      }
    }
      /*fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          console.log(ip);
      });*/
    },
}
</script>

